import toast from "react-hot-toast";
import {AppDispatch} from "../../../../redux/store";
import {postProfileSlice} from "./postProfile.slice";
import PRIVATE_API, {PUBLIC_API} from "../../../../api";
import {toastCreator} from "../../../../config/toast";
import {profileSlice, uploadAvatar} from "./profileSlice";
import {RootProfile} from "../../../../models/profile";
import {logout} from "../../../../components/forms/login/reducer/action";
import {getCompanyByUid} from "../../edit-company/CompanyReducer/action";
import {designSlice} from "../../../Design/DesignReducer/slice";

export const addProfile = ({full_name, position, email}: RootProfile) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postProfileSlice.actions.addProfile());
    await PRIVATE_API.patch("edit-profile/", {
      full_name,
      position,
      email,
    });
    dispatch(postProfileSlice.actions.addProfileSuccess());
    dispatch(getProfile());
    toast.success("Profile successfully updated");
  } catch (e: any) {
    toastCreator("error", e.response?.data?.message || e.detail);
    dispatch(
      postProfileSlice.actions.addProfileError(
        e.response?.data?.message || e.detail
      )
    );
  }
};

export const addProfileAvatar = (file: File | string) => async (dispatch: AppDispatch) => {
  try {
    let formData = new FormData()
    formData.append('photo', file)
    const config = {
      headers: {'content-type': 'multipart/form-data'}
    }
    console.log(file, formData)
    dispatch(postProfileSlice.actions.addProfile());
    const {data} = await PRIVATE_API.patch<RootProfile>("edit-profile/", formData, config);
    dispatch(uploadAvatar(data.photo))
    dispatch(postProfileSlice.actions.addProfileSuccess());
    dispatch(getProfile());
    toast.success("Profile avatar updated");
  } catch (e: any) {
    toastCreator("error", e.response?.data?.message || e.detail);
    dispatch(
      postProfileSlice.actions.addProfileError(
        e.response?.data?.message || e.detail
      )
    );
  }
};

export const getProfile = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(profileSlice.actions.getProfile());
    const {data} = await PRIVATE_API.get<RootProfile>(
      "get-profile"
    );
    dispatch(profileSlice.actions.getProfileSuccess(data));
    dispatch(designSlice.actions.getDesignSuccess(data))
  } catch (e: any) {
    if (e.response?.data?.message?.includes("token")) logout();
    dispatch(profileSlice.actions.getProfileError(e.response?.data?.message));
  }
};

export const getUserById =
  (uid: string) => async (dispatch: AppDispatch) => {
    try {
      dispatch(profileSlice.actions.getProfile());
      const {data} = await PUBLIC_API.get<RootProfile>(
        `get-profile?search=${uid}`
      );
      logout();
      dispatch(profileSlice.actions.getProfileSuccess(data));
      dispatch(designSlice.actions.getDesignSuccess(data))
      dispatch(getCompanyByUid(uid));
    } catch (e: any) {
      dispatch(profileSlice.actions.getProfileError(e.response?.data?.message));
    }
  };
