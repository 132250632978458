import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  DARK_SYSTEM_COLOR,
  DARK_THEME,
  KEY_THEME,
  LIGHT_SYSTEM_COLOR,
  LIGHT_THEME, main_color_theme,
  SYSTEM_FONT
} from "../../../config/colors";
import {RootProfile} from "../../../models/profile";

export type TypesTheme = typeof DARK_THEME | typeof LIGHT_THEME
export type TypesPayloadTheme = 'DARK' | "LIGHT"

interface IDesignState {
  def_font: string;
  def_color: string;
  colors: string[];
  def_theme: TypesPayloadTheme;
  system_color: TypesTheme;
  isLoading: boolean;
  isError: string | null;
}

const initialState: IDesignState = {
  def_font: SYSTEM_FONT,
  def_color: DARK_SYSTEM_COLOR,
  def_theme: "DARK",

  colors: ['#E54D52', '#BA9C57', '#593CF8', '#58AF40'],
  system_color: LIGHT_THEME,
  isError: null,
  isLoading: false
}

export const designSlice = createSlice({
  name: 'design',
  initialState,
  reducers: {

    setLoading(state){
      state.isLoading = true
    },

    addedDesign(state, action: PayloadAction<string | null>){
      state.isLoading = false
      state.isError = action.payload
    },
    getDesignSuccess(state, {payload: {designs}}: PayloadAction<RootProfile>){
      state.isLoading = false
      if (!!designs){
        state.def_theme = designs.dark_theme ? 'DARK'  : 'LIGHT'
        state.system_color = designs.dark_theme ? LIGHT_THEME : DARK_THEME
        state.def_color = designs.color
        state.def_font = designs.font
      }
    },




    changeTheme(state, {payload}: PayloadAction<TypesPayloadTheme>) {
      state.def_theme = payload
      state.system_color = main_color_theme[payload] as TypesTheme
      if (payload === "DARK") {
        state.def_color = DARK_SYSTEM_COLOR
      } else {
        state.def_color = LIGHT_SYSTEM_COLOR
      }
    },


    changeColor(state, {payload}: PayloadAction<string>) {
      state.def_color = payload
    },
    toSystemColor(state) {
      state.def_color = state.system_color
      localStorage.setItem(KEY_THEME, state.system_color)
      state.def_font = SYSTEM_FONT
    },

    addColors(state, {payload}: PayloadAction<string>) {
      if (state.colors.some(el => el !== payload)) {
        state.colors.pop()
        state.colors.unshift(payload)
      }
    },

    setFont(state, {payload}: PayloadAction<string>) {
      state.def_font = payload
    },
  }
})

export const {changeTheme, toSystemColor, changeColor, addColors, setFont} = designSlice.actions
export const DesignReducer = designSlice.reducer