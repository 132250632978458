
import toast from "react-hot-toast";
import {postLeadSlice} from "./postLeads";
import PRIVATE_API, {PUBLIC_API} from "../../../api";
import {AppDispatch} from "../../../redux/store";
import {toastCreator} from "../../../config/toast";
import {leadsSlice} from "./slice";
import {RootLeads} from "../../../models/leads";


export const sendLead = (value: any, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postLeadSlice.actions.setLoading());
    const {data} = await PUBLIC_API.post(`api/leads/`, value)
    dispatch(postLeadSlice.actions.onSendLead(null));
    toast.success("your data has been sent successfully");
    onClose()
  } catch (e: any) {
    toastCreator("error", e.response?.data?.message || e.detail);
    dispatch(
      postLeadSlice.actions.onSendLead(
        e.response?.data?.message || e.detail
      )
    );
  }
}

export const getLeads = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(leadsSlice.actions.setLoading());
    const {data} = await PRIVATE_API.get<RootLeads>('api/leads/')
    dispatch(leadsSlice.actions.getLeads(data));
  } catch (e: any) {
    dispatch(
      leadsSlice.actions.getLeadsError(
        e.response?.data?.message || e.detail
      )
    );
  }
}

export const deleteLead = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(leadsSlice.actions.setLoading());
    await PRIVATE_API.delete(`api/leads/${id}/`);
    toastCreator("success", "Data deleted successfully");
    dispatch(getLeads());
  } catch (error: any) {
    dispatch(leadsSlice.actions.getLeadsError(error.message));
    toastCreator("error", "Error when deleting");
  }
};