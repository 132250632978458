import React from 'react';
import {Box, Button, Divider, Flex, Heading} from "@chakra-ui/react";
import TitleText from "../../../../components/ui/text/TitleText";
import {useAppSelector} from "../../../../config/hooks/redux";
import CompanyButton from "./CompanyButton";
import {IContactButton} from "../../../../models/custom";
import {box_theme} from "../../../../config/colors";
import LocationButton from "./LocationButton";
import {navigateToUrl} from "../../../../config/helpers";

const CompanyDetails = () => {
  const {buttons, company: {locations}} = useAppSelector(s => s.CompanyReducer)
  const {def_theme, system_color} = useAppSelector(state => state.DesignReducer)
  const link_buttons: IContactButton[] = buttons.filter(el => el.type === 'Web-site')
  const all_buttons: IContactButton[] = buttons.filter(el => el.type !== 'Web-site')
  return (
    <Box mt='50px'>
      <TitleText>Company Details</TitleText>
      <Flex mt='15px' overflowX='auto' className='unscroll'>
        {link_buttons?.map((btn, idx) => (
          <Box key={idx} px='14px' pt='14px' pb='9px'  bg={box_theme[def_theme]} w='240px' rounded='16px'
               mr='14px'>
            <Flex alignItems='center' w='100%' justifyContent='space-between'>
              <Flex
                alignItems='center' justifyContent='center'
                w='40px' h='40px' rounded='50%'
                bg='rgba(79, 79, 79, 1)' mr='10px'>{btn.icon}</Flex>
              <Box w='149px'>
                <Heading
                  noOfLines={1}
                  fontFamily='Poppins'
                  fontWeight='400'
                  fontSize='18px'
                  lineHeight='28px'
                  letterSpacing='0.35px'
                  color={system_color}
                >{btn.value}</Heading>
              </Box>
            </Flex>
            <Button
              onClick={() => navigateToUrl(btn.value)}
              variant='none' bg='transparent' h='25px' w='100%' mx={1}
              rounded='8px' border='1px solid rgba(255, 255, 255, 0.8)'
              color='rgba(255, 255, 255, 0.8)' fontWeight='500' mt={3}
              fontSize='11px' lineHeight='13px' fontFamily='Poppins'
            >Open link</Button>
          </Box>
        ))}
        <LocationButton/>
      </Flex>


      <Box p={2} mt='14px' rounded='14px' bg={box_theme[def_theme]}>
        {all_buttons?.map((el, idx) => (
          <>
            <CompanyButton btn={el} key={idx}/>
            {idx !== (buttons.length - 1) && <Box bg='rgba(0, 0, 0, 1)' h='1px' m='11px'/>}
          </>
        ))}
      </Box>
    </Box>
  );
};

export default CompanyDetails;