import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface AddGalleyState {
  isError: string | null;
  isLoading: boolean;
}

const initialState: AddGalleyState = {
  isError: null,
  isLoading: false,
}

export const gallerySLice = createSlice({
  name: 'gallery/post',
  initialState,
  reducers: {
    addGallery(state) {
      state.isLoading = true;
    },
    addedGallery(state, action: PayloadAction<string | null>) {
      state.isLoading = false;
      state.isError = action.payload
    },
  }
})

export const GalleryReducer = gallerySLice.reducer
