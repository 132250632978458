import React from 'react';

const LinkedinSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.1759 2.16667H3.82419C3.6179 2.16381 3.41307 2.20161 3.22139 2.27791C3.02971 2.35422 2.85493 2.46753 2.70705 2.61139C2.55917 2.75525 2.44108 2.92683 2.35951 3.11633C2.27795 3.30583 2.23452 3.50955 2.23169 3.71584V22.2842C2.23452 22.4905 2.27795 22.6942 2.35951 22.8837C2.44108 23.0732 2.55917 23.2448 2.70705 23.3886C2.85493 23.5325 3.02971 23.6458 3.22139 23.7221C3.41307 23.7984 3.6179 23.8362 3.82419 23.8333H22.1759C22.3821 23.8362 22.587 23.7984 22.7787 23.7221C22.9703 23.6458 23.1451 23.5325 23.293 23.3886C23.4409 23.2448 23.559 23.0732 23.6405 22.8837C23.7221 22.6942 23.7655 22.4905 23.7684 22.2842V3.71584C23.7655 3.50955 23.7221 3.30583 23.6405 3.11633C23.559 2.92683 23.4409 2.75525 23.293 2.61139C23.1451 2.46753 22.9703 2.35422 22.7787 2.27791C22.587 2.20161 22.3821 2.16381 22.1759 2.16667ZM8.76419 20.3017H5.51419V10.5517H8.76419V20.3017ZM7.13919 9.18667C6.69097 9.18667 6.26112 9.00862 5.94418 8.69168C5.62724 8.37474 5.44919 7.94489 5.44919 7.49667C5.44919 7.04845 5.62724 6.6186 5.94418 6.30166C6.26112 5.98472 6.69097 5.80667 7.13919 5.80667C7.37719 5.77968 7.61821 5.80326 7.84647 5.87588C8.07473 5.94849 8.28507 6.0685 8.46373 6.22805C8.6424 6.38759 8.78534 6.58307 8.88322 6.80169C8.98109 7.02031 9.03168 7.25714 9.03168 7.49667C9.03168 7.7362 8.98109 7.97303 8.88322 8.19165C8.78534 8.41027 8.6424 8.60575 8.46373 8.7653C8.28507 8.92484 8.07473 9.04485 7.84647 9.11746C7.61821 9.19008 7.37719 9.21366 7.13919 9.18667ZM20.4859 20.3017H17.2359V15.0692C17.2359 13.7583 16.77 12.9025 15.5892 12.9025C15.2237 12.9052 14.8679 13.0198 14.5696 13.2309C14.2713 13.4421 14.0449 13.7396 13.9209 14.0833C13.8361 14.338 13.7993 14.6061 13.8125 14.8742V20.2908H10.5625C10.5625 20.2908 10.5625 11.4292 10.5625 10.5408H13.8125V11.9167C14.1078 11.4044 14.5372 10.9823 15.0545 10.696C15.5718 10.4096 16.1575 10.2698 16.7484 10.2917C18.915 10.2917 20.4859 11.6892 20.4859 14.69V20.3017Z" fill="white"/>
    </svg>

  );
};

export default LinkedinSvg;