import React from 'react';
import {Box, Button, useDisclosure} from "@chakra-ui/react";
import {FiPlus} from "react-icons/fi";
import CustomModal from "../../../../components/custom-buttons/CustomModal";
import {addCustom} from "../../../../components/custom-buttons/CustomReducer/actions";
import {useAppDispatch, useAppSelector} from "../../../../config/hooks/redux";
import {IContactButton} from "../../../../models/custom";

interface Props {
  disabled: boolean
}

const AddContactsBtn = ({disabled}: Props) => {
  const {user} = useAppSelector(s => s.ProfileReducer)
  const {onOpen, onClose, isOpen} = useDisclosure()
  const dispatch = useAppDispatch()
  const onSubmit = (btn: IContactButton) => {
    dispatch(addCustom(btn, user.id, 0, onClose))
  }

  return (
    <>
      <Button
        onClick={onOpen}
        variant='none'
        bg='transparent'
        border='1px solid rgba(55, 154, 255, 1)'
        color='rgba(55, 154, 255, 1)'
        fontWeight='600' h='36px' px={3} py='0'
        fontFamily='Poppins'
        fontSize='14px'
        lineHeight='22px'
        opacity={disabled ? ".7" : '1'}
        _active={{opacity: '.7'}}
        isDisabled={disabled}
      >
        CONTACTS <Box fontSize='0.9rem'><FiPlus/></Box>
      </Button>

      <CustomModal onClose={onClose} isOpen={isOpen} onSubmit={onSubmit}/>
    </>
  );
};

export default AddContactsBtn;