import React from 'react';
import {Box, Flex} from "@chakra-ui/react";
import {INTERFACE_WIDTH, SAVE_BTN_HEIGHT} from "../../../config/_variables";
import ExchangeContact from "./ExchangeContact";
import SaveContact from "./SaveContact";

const FixedButtons = () => {
  return (
    <Flex position='fixed' zIndex='20' bottom='0' left='0' right='0'>
      <Box mx='auto'
           w={INTERFACE_WIDTH}
           h={SAVE_BTN_HEIGHT} bg='rgba(49, 51, 53, 0.7)'
           borderTop='1px solid #2E2E2E' >
        <Flex pt='4' px={4} w='100%'>
          <ExchangeContact/>
          <Box w='12px'/>
          <SaveContact/>
        </Flex>
      </Box>
    </Flex>
  );
};

export default FixedButtons;