import {Tab, TabIndicator, TabList} from "@chakra-ui/react";
import React, {Component, ReactNode} from "react";

//local
import {tabList} from "./helpersStatistics";
import {StatisticType} from "../../models/analytics";

interface IProps {
  setTypeDate: React.Dispatch<React.SetStateAction<StatisticType>>;
}

class StaticTabMenu extends Component<IProps> {
  render(): ReactNode {
    const {setTypeDate} = this.props;
    return (
      <>
        <TabList display="flex" justifyContent="space-evenly" color="#353535" columnGap={3}>
          {tabList.map((el, idx) => (
            <Tab
              key={idx}
              px="0" w='100%'
              bg='rgba(49, 51, 53, 0.7)'
              rounded='12px'
              color='rgba(255, 255, 255, .5)'
              _selected={{color: "#ffffff"}}
              textTransform="capitalize"
              onClick={() => setTypeDate(el)}
            >
              {el}
            </Tab>
          ))}
          <Tab
            px="0" w='100%'
            bg='rgba(49, 51, 53, 0.7)'
            rounded='12px'
            color='rgba(255, 255, 255, .5)'
            _selected={{color: "#ffffff"}}
            textTransform="capitalize"
            // onClick={() => setTypeDate(el)}
          >
            Total
          </Tab>
        </TabList>
        {/*<TabIndicator*/}
        {/*  mt="-1.5px"*/}
        {/*  height="2px"*/}
        {/*  bg="#ffffff"*/}
        {/*  borderRadius="1.5px"*/}
        {/*/>*/}
      </>
    );
  }
}

export default StaticTabMenu;
