import {AppDispatch} from "../../../../redux/store";
import {loginSlice} from "./slice";
import {PUBLIC_API} from "../../../../api";
import {removeLocalStorage, toLocalStorage} from "../../../../config/helpers";
import {toastCreator} from "../../../../config/toast";
import {getProfile} from "../../../../pages/Profile/edit-profile/ProfileReducer/actions";
import {getCompany} from "../../../../pages/Profile/edit-company/CompanyReducer/action";



export const onLogin = (value: any, navigate: any) => async (dispatch: AppDispatch) => {
  dispatch(loginSlice.actions.setLoading())
  PUBLIC_API.post("auth/jwt/create/", value)
    .then(({data}) => {
      console.log(data)
      toLocalStorage("access", data.access);
      toLocalStorage("refresh", data.refresh);
      dispatch(loginSlice.actions.onToggleLoginForm(false))
      dispatch(getProfile())
      dispatch(getCompany())
      toastCreator("success", "Welcome to profile");
      navigate(`/profile`);
      dispatch(loginSlice.actions.onLogin(null));
    })
    .catch((e) => {
      toastCreator("error", e.response?.data?.detail || "Error");
      dispatch(loginSlice.actions.onLogin(e.response?.data?.detail));
    });
}

export const logout = () => {
  removeLocalStorage("access");
  removeLocalStorage("refresh");
};