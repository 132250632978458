import React, { FC } from "react";
import { Box, Text } from "@chakra-ui/react";

//local
import { getYouTubeVideoId } from "../../../../config/helpers";
import {IVideo} from "../../../../models/company";

type IProps = {
  video: IVideo;
};

const YoutubeVideo: FC<IProps> = ({ video }) => {
  return (
    <Box pb="0.8rem">
      <iframe
        className="responsive-iframe"
        src={`https://www.youtube.com/embed/${getYouTubeVideoId(video.link)}`}
        title="YouTube video player"
        width='100%' height='241px'
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
      <Text
        mt="15px"
        fontWeight={400}
        fontSize={16}
        lineHeight="24px"
        textAlign="center"
      >
        {video.title}
      </Text>
    </Box>
  );
};

export default YoutubeVideo;
