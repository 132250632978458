import React from 'react';
import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import {GoTriangleRight} from "react-icons/go";
import {IContactButton} from "../../../../models/custom";
import {hexToRGB, navigateToUrl} from "../../../../config/helpers";
import {useAppSelector} from "../../../../config/hooks/redux";

const CompanyButton = ({btn}: { btn: IContactButton }) => {
  const {def_color, system_color} = useAppSelector(state => state.DesignReducer)
  const handleClick = () => {
    navigateToUrl(btn.offer + btn.value.trim(), btn.type === "Phone");
  }
  return (
    <Flex
      onClick={handleClick}
      cursor='pointer'
      h='64px' w='100%'
      justifyContent='space-between'
      alignItems='center'
    >
      <Flex alignItems='center'>
        <Box mr='13px' color={hexToRGB(def_color, 0.4)} fontSize='1.6rem'><GoTriangleRight/></Box>
        <Box>
          <Text
            textAlign='start'
            color={system_color}
            fontWeight="400"
            fontSize="16px"
            lineHeight="12px"
            fontFamily='Poppins'
          >
            {btn.name}
          </Text>
        </Box>
      </Flex>

      <Flex
        justifyContent='center' alignItems='center'
        // bg='rgba(186, 156, 87, 0.2)'
        bg={hexToRGB(def_color, 0.4)}
        rounded='31px' w='64px' h='64px'
        color='rgba(255, 255, 255, 1)' fontSize='1.4rem'
      >
        {btn.icon}
      </Flex>
    </Flex>
  );
};

export default CompanyButton;