import {IVideo} from "../../../../models/company";
import {AppDispatch} from "../../../../redux/store";
import {postProfileSlice} from "../../edit-profile/ProfileReducer/postProfile.slice";
import PRIVATE_API from "../../../../api";
import toast from "react-hot-toast";
import {toastCreator} from "../../../../config/toast";
import {getCompany} from "./action";

export const addVideo = (value: IVideo) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postProfileSlice.actions.addProfile());
    await PRIVATE_API.post(`api/youtube/`, value)
    dispatch(postProfileSlice.actions.addProfileSuccess());
    toast.success("YouTube link added");
    dispatch(getCompany())
  } catch (e: any) {
    toastCreator("error", e.response?.data?.message || e.detail);
    dispatch(
      postProfileSlice.actions.addProfileError(
        e.response?.data?.message || e.detail
      )
    );
  }
}

export const removeVideo = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postProfileSlice.actions.addProfile())
    await PRIVATE_API.delete(`api/youtube/${id}/delete/`)
    toast.success('Video removed')
    dispatch(getCompany())
    dispatch(postProfileSlice.actions.addProfileSuccess())
  }catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(
      postProfileSlice.actions.addProfileError(
        e.response?.data?.message || e.detail
      )
    );
  }
}