import React from 'react';
import DefButton from "../../../../components/ui/Buttons/DefButton";
import {saveContactVcf} from "../../../../config/helpers";
import {useAppDispatch, useAppSelector} from "../../../../config/hooks/redux";
import {saveContact} from "./reducer/action";

const SaveContact = () => {
  const dispatch = useAppDispatch()
  const {user: {email, full_name, photo, id}, buttons} = useAppSelector(s => s.ProfileReducer)
  const {isLoading} = useAppSelector(s => s.SaveContactReducer)
  const phone = buttons.find(el => el.type === "Phone")?.value

  const handleClick = () => {
    dispatch(saveContact(id))
    // saveContactVcf(full_name, photo, email, String(phone))
  }

  return (
    <DefButton
      isLoading={isLoading}
      type="button"
      onClick={handleClick}
    >Save Contact</DefButton>
  );
};

export default SaveContact;