import React from 'react';
import {Heading} from "@chakra-ui/react";
import {useAppSelector} from "../../../config/hooks/redux";

interface Props {
  children: JSX.Element | string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string
}

const TitleText = ({children, lineHeight = '28px', fontWeight = '700', fontSize = '18px'}: Props) => {
  const {system_color} = useAppSelector(state => state.DesignReducer)
  return (
    <Heading
      textAlign='start'
      color={system_color}
      fontWeight={fontWeight}
      fontSize={fontSize}
      lineHeight={lineHeight}
      fontFamily='Poppins'
    >
      {children}
    </Heading>
  );
};

export default TitleText;