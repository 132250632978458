import React, {useState} from 'react';
import LoginSvg from "../../../assests/svg/LoginSvg";
import MenuItemUi from "../../../pages/Home/ProfileData/MenuBtn/MenuItemUi";
import {Box, Text} from "@chakra-ui/react";
import Placement from "../../ui/placement";
import ProfileInput from "../../ui/inputs/ProfileInput";
import DefButton from "../../ui/Buttons/DefButton";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {useNavigate, useParams} from "react-router-dom";
import {onToggleLoginForm} from "./reducer/slice";
import {onLogin} from "./reducer/action";

const Login = () => {
  const {uid} = useParams()
  const [value, setValue] = useState({
    password: ''
  })
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const {system_color} = useAppSelector(state => state.DesignReducer)
  const {isOpenLoginForm, isLoading} = useAppSelector(s => s.LoginReducer)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(onLogin({...value, uid}, navigate))
  }

  return (
    <>
      <MenuItemUi name={"Log In"} icon={<LoginSvg/>} onClick={() => dispatch(onToggleLoginForm(true))}/>

      <Placement isOpen={isOpenLoginForm} onClose={() => dispatch(onToggleLoginForm(false))} title={"Login"}>
        <form onSubmit={onSubmit}>
          <ProfileInput
            handleChange={handleChange}
            type="password"
            value={value.password}
            required={true}
            name={"password"}
            placeholder={"Password"}
          />
          <Text
            fontSize='11px' letterSpacing='0.07 px' px='6px'
            fontWeight='400' fontFamily={"Poppins"}
            lineHeight='12px' color={system_color} opacity='.6'
          >Enter your standard password.</Text>
          <Box mt='37px'>
            <DefButton isLoading={isLoading}>Send</DefButton>
          </Box>
        </form>
      </Placement>
    </>
  );
};

export default Login;