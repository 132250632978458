import React from "react";
import { Box } from "@chakra-ui/react";

const ErrorText = ({
                     children,
                   }: {
  children: JSX.Element | string | undefined;
}) => {
  return (
    <Box
      fontSize="12px"
      letterSpacing="0.5px"
      fontWeight="400"
      fontFamily="Poppins"
      w="full"
      lineHeight="16px"
      color="#B3261E"
      px={4}
      mt={1}
    >
      {children}
    </Box>
  );
};

export default ErrorText;
