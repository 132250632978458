import React from 'react';
import {Box, Button, Flex, Heading, Text} from "@chakra-ui/react";
import {useAppSelector} from "../../../../config/hooks/redux";
import {location_button} from "../../../../components/custom-buttons/data";
import {navigateToUrl} from "../../../../config/helpers";
import toast from "react-hot-toast";

const LocationButton = () => {
  const { company: {locations}} = useAppSelector(s => s.CompanyReducer)
  const handleClick = () => {
    if (!!locations?.map_link){
      navigateToUrl(locations.map_link)
    }else {
      toast.error("Map link not provided")
    }
  }
  if (locations === null){
    return null
  }
  return (
    <Box px='14px' pt='14px' pb='9px' bg='rgba(40, 49, 68, 1)' w='240px' rounded='16px'
         mr='14px'>
      <Flex alignItems='center' w='100%' justifyContent='space-between'>
        <Flex
          alignItems='center' justifyContent='center'
          w='40px' h='40px' rounded='50%'
          bg='rgba(79, 79, 79, 1)' mr='10px'>{location_button.icon}</Flex>
        <Box w='149px'>
          <Heading
            noOfLines={1}
            fontFamily='Poppins'
            fontWeight='400'
            fontSize='18px'
            lineHeight='28px'
            letterSpacing='0.35px'
            color='rgba(255, 255, 255, 1)'
          >{locations.main_branch || locations.map_link}</Heading>
          <Text
            noOfLines={1}
            fontFamily='Poppins'
            fontWeight='300'
            fontSize='10px'
            lineHeight='12px'
            letterSpacing='0.35px'
            color='rgba(255, 255, 255, .6)'
          >
            {locations.city_country}&nbsp;
            {locations.street}&nbsp;
            {locations.tower_name}
          </Text>
        </Box>
      </Flex>
      <Button
        onClick={handleClick}
        variant='none' bg='transparent' h='25px' w='100%' mx={1}
        rounded='8px' border='1px solid rgba(255, 255, 255, 0.8)'
        color='rgba(255, 255, 255, 0.8)' fontWeight='500' mt={3}
        fontSize='11px' lineHeight='13px' fontFamily='Poppins'
      >Go to map</Button>
    </Box>
  );
};

export default LocationButton;