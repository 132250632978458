import React from 'react';
import {Box, Button, Divider, Flex, Heading, Menu, MenuButton, MenuList, Progress} from "@chakra-ui/react";
import {INTERFACE_WIDTH, HEADER_HEIGHT} from "../../config/_variables";
import {MdOutlineArrowBackIos} from "react-icons/md";
import {IoIosLogOut, IoMdMore} from "react-icons/io";
import {useNavigate} from "react-router-dom";
import {useAppSelector} from "../../config/hooks/redux";
import {main_theme} from "../../config/colors";
import MenuItemUi from "../../pages/Home/ProfileData/MenuBtn/MenuItemUi";
import {logout} from "../forms/login/reducer/action";
import {CiLock} from "react-icons/ci";
import ForgotPassword from "../forms/forgot-password";

interface Props {
  title: string;
  pathToBack: string;
  isLoading?: boolean
}

const InterfaceHeader = ({pathToBack, title, isLoading}: Props) => {
  const {def_theme, system_color} = useAppSelector(state => state.DesignReducer)
  const {user: {uid}} = useAppSelector(state => state.ProfileReducer)
  const navigate = useNavigate()
  const onLogout = () => {
    logout()
    navigate(`/user/${uid}`)
  }

  return (
    <Box position='fixed' zIndex='100' top='0' left='0' right='0'>
      <Box
        mx='auto'
        maxW={INTERFACE_WIDTH}
      >
        <Flex
          pt='12px' px='10px' pb={1}
          h={HEADER_HEIGHT} bg={main_theme[def_theme]}
          justifyContent='space-between'
          alignItems='center'>
          <Flex
            opacity='0'
            justifyContent='center' alignItems='center'
            color={system_color}
            fontSize='1.6rem' w='24px' cursor='pointer'
            _active={{opacity: '.7'}}
          ><MdOutlineArrowBackIos/></Flex>
          <Heading
            fontFamily='Poppins'
            lineHeight='22px'
            color={system_color}
            fontSize='18px'
            fontWeight='600'
          >
            {title}
          </Heading>
          <Menu
            closeOnSelect={false}>
            <MenuButton
              as={Button} variant='none'
              justifyContent='center'
              alignItems='center'
              color={system_color} p={'0'}
              fontSize='1.9rem' w='28px' cursor='pointer'
            >
              <IoMdMore/>
            </MenuButton>
            <MenuList
              maxW='210px'
              bg='rgba(49, 51, 53, 0.7)'
              p='0' border='none'
              rounded='13px' overflow='hidden'
            >
              <ForgotPassword nameBtn={"Change password"} icon={<CiLock/>}/>
              <Divider color='#FFFFFF33'/>
              <MenuItemUi name={"Log out"} icon={<IoIosLogOut/>} onClick={onLogout}/>
            </MenuList>
          </Menu>
        </Flex>
        {isLoading && <Progress size='xs' bg='none' w='100%' isIndeterminate/>}
      </Box>
    </Box>
  );
};

export default InterfaceHeader;