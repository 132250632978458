import React from 'react';
import {Box, Button, Flex, Stack, Text} from "@chakra-ui/react";
import {AiOutlinePlus} from "react-icons/ai";
import FileInput from "../../../../../components/ui/inputs/FileInput";

const AddPhotoButton = ({handleChange}: { handleChange: (e: React.ChangeEvent) => void }) => {
  return (
    <Stack as='label' w='100%' rounded='14px' h='50px' zIndex='2' position='relative' display='inline-block'>
      <FileInput handleChange={handleChange} accept={['.png', '.jpeg', '.jpg']} multi={true}/>
      <Text
        as='span' w='100%' h='100%'
        position='relative'
        display='inline-block'
        cursor='pointer'
      >
        <Button
          as='span'
          position='relative'
          display='inline-block'
          cursor='pointer' w='100%' h='100%'
          outline='none' variant='none'
          rounded='14px' bg='transparent' mx='auto' p='0'
          border='1px solid rgba(255, 255, 255, 1)' color='white'
          _active={{bg: '#FFFFFF', color: '#007AFF'}}
        >
          <Flex w='100%' h='100%' fontFamily='Poppins'
                fontWeight='500' justifyContent='center' alignItems='center'
                fontSize='17px' rounded='14px'>
            <Box fontSize='1.2rem' mr={2}>
              <AiOutlinePlus/></Box>
            Add
          </Flex></Button>
      </Text>
    </Stack>
  )
}
export default AddPhotoButton