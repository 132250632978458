import React, {useEffect} from 'react';
import {NavLink, RouteProps} from "react-router-dom";
import DesignSvg from "../../assests/svg/DesignSvg";
import AnalyticsSvg from "../../assests/svg/AnalyticsSvg";
import LeadsSvg from "../../assests/svg/LeadsSvg";
import ProfileSvg from "../../assests/svg/ProfileSvg";
import {Box, Flex, Text} from "@chakra-ui/react";
import {INTERFACE_WIDTH, NAVBAR_HEIGHT} from "../../config/_variables";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";
import {getCompany} from "../../pages/Profile/edit-company/CompanyReducer/action";
import {getProfile} from "../../pages/Profile/edit-profile/ProfileReducer/actions";
import {useAuthUser} from "../../config/hooks/userAccess";

const navbar = [
  {
    name: "Design",
    icon: <DesignSvg/>,
    path: "/design",
  },
  {
    name: "Analytics",
    icon: <AnalyticsSvg/>,
    path: "/analytics",
  },
  {
    name: "Leads",
    icon: <LeadsSvg/>,
    path: "/leads",
  },
  {
    name: "Profile",
    icon: <ProfileSvg/>,
    path: "/profile",
  },
]

export const navbar_theme = {
  DARK: "rgba(49, 51, 53, 0.7)",
  LIGHT: "rgba(119, 119, 119, 0.7)",
}

export const navbar_color_theme = {
  DARK: "rgba(117, 117, 117, 1)",
  LIGHT: "rgba(255, 255, 255, 1)",
}

const Navbar = () => {
  const {def_theme} = useAppSelector(state => state.DesignReducer)
  const dispatch = useAppDispatch()
  const isAuth = useAuthUser()
  useEffect(() => {
  if (isAuth) {
      dispatch(getProfile());
      dispatch(getCompany())
    }
  }, [isAuth])
  return (
    <Flex position='fixed' zIndex='20' bottom='0' left='0' right='0'>
      <Box mx='auto'
           w={INTERFACE_WIDTH}
           h={NAVBAR_HEIGHT} bg={navbar_theme[def_theme]}
           borderTop='1px solid #2E2E2E' pt='6px'>
        <Flex
          justifyContent='space-between'
          className='navbar' h={'49px'}>
          {navbar.map((nav, idx) => (
            <Flex
              key={nav.name}
              flexDirection='column'
              alignItems='center'
              justifyContent='center'
              w='25%'
              h={'100%'} cursor='pointer'
              borderRight={idx !== navbar.length - 1 ? '1px solid rgba(65, 65, 65, 1)' : ''}
              _active={{bg: '#171717'}}
            >
              <NavLink
                to={nav.path} className='navbar--link'
                style={{color: navbar_color_theme[def_theme]}}
              >
                <Flex className='nav-icon' h='26px' justifyContent='center'
                      alignItems='center'>{nav.icon}</Flex>
                <Text
                  fontFamily='Poppins' lineHeight='12px'
                  fontWeight='500' fontSize='10px'
                  mt={1} letterSpacing='0.5px'
                >{nav.name}</Text>
              </NavLink>
            </Flex>
          ))}
        </Flex>
      </Box>
    </Flex>
  );
};

export default Navbar;