import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ForgotPassSlice {
  isOpenForgotPassword: boolean;
  isLoading: boolean;
  isError: string | null
}

const initialState: ForgotPassSlice = {
  isError: null,
  isLoading: false,
  isOpenForgotPassword: false
}

export const forgotPasswordSlice = createSlice({
  name: "forgot-password",
  initialState,
  reducers: {
    setLoading(state){
      state.isLoading = true
    },
    changedPassword(state, action: PayloadAction<string | null>){
      state.isLoading = false;
      state.isError = action.payload
    },
    onToggleChangePassword(state, action: PayloadAction<boolean>){
      state.isOpenForgotPassword = action.payload
    },
  }
})

export const ForgotPasswordReducer = forgotPasswordSlice.reducer
export const {setLoading, changedPassword, onToggleChangePassword} = forgotPasswordSlice.actions