import React from 'react';
import {Box, Flex, Text} from "@chakra-ui/react";
import {add_btn_theme} from "../../../../config/colors";
import {useAppDispatch, useAppSelector} from "../../../../config/hooks/redux";
import {IContactButton} from "../../../../models/custom";
import {removeButton} from "../../../../components/custom-buttons/CustomReducer/actions";
import {HiMinusSm} from "react-icons/hi";

const EditButton = ({btn, isLocation}: { btn: IContactButton, isLocation?: boolean }) => {
  const {def_theme} = useAppSelector(state => state.DesignReducer)
  const dispatch = useAppDispatch()
  const deleteButton = () => {
    dispatch(removeButton(Number(btn.id)))
  }
  return (
    <Flex
      justifyContent='space-between' h='58px'
      rounded='8px' bg={add_btn_theme[def_theme]}
      pr='9px' pt='6px' pb='8px' pl='15px'
    >
      <Flex
        flexDirection='column' w='100%'
        justifyContent='space-between' h='100%'
      >
        <Flex
          w='100%' justifyContent='space-between'>
          <Box fontSize='1.6rem' color='#FFFFFF'>{btn.icon}</Box>

          {!isLocation && <Flex
            onClick={deleteButton}
            justifyContent='center' alignItems='center'
            color='#D9D9D9' bg='#DC2626' w='19.9px' h='20px'
            rounded='50%' fontSize='1rem'
            _active={{opacity: 0.7}} cursor='pointer'
          ><HiMinusSm/>
          </Flex>}
        </Flex>

        <Text
          fontFamily='Poppins'
          fontWeight='400'
          fontSize='11px'
          lineHeight='12px'
          letterSpacing='0.07px'
          color='rgba(255, 255, 255, 1)'
        >{btn.name}</Text>
      </Flex>

      {/*<Box*/}
      {/*  fontSize='1.2rem' cursor='pointer'*/}
      {/*  color='rgba(229, 229, 229, 1)'*/}
      {/*  _active={{opacity: '.7'}}><RiEditBoxLine/></Box>*/}
    </Flex>
  );
};

export default EditButton;