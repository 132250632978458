import React from 'react';
import {Box, Button, Divider, Flex, Modal, ModalContent, ModalOverlay, Text, useDisclosure} from "@chakra-ui/react";
import {useAppDispatch, useAppSelector} from "../../../../config/hooks/redux";
import {deleteCompany} from "../CompanyReducer/action";

const RemoveCompany = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch()
  const {company, buttons, companies} = useAppSelector(s => s.CompanyReducer)
  const onDelete = () => {
    dispatch(deleteCompany(company.id, onClose))
  }
  console.log(company)
  return (
    <Box mt={6}>
      <Button
        onClick={onOpen}
        isDisabled={!companies.length}
        variant='none'
        bg="#DC2626"
        h={'44px'} w='100%'
        color='rgba(255, 255, 255, 1)'
        fontSize='14px'
        fontWeight='600'
        lineHeight='22px'
        textTransform='uppercase'
        _hover={{bg: '#DC2626'}}
      >delete company</Button>

      <Modal isOpen={isOpen} onClose={onClose} size={{sm: 'md', base: 'sm'}} isCentered={true}>
        <ModalOverlay/>
        <ModalContent px='0' pb='0' pt='50px' bg='rgba(49, 51, 53, 0.7)' rounded='20px'>
          <Flex flexDirection="column" alignItems="center">
            <Text
              fontFamily='Poppins'
              color="#FFFFFF"
              fontWeight="400"
              fontSize="15px"
              lineHeight="22.5px"
              textAlign="center"
              w="70%"
            >
              Are you sure you want to delete the company?
            </Text>
            <Flex mt="20px" w="100%" h='67.5px' borderTop='0.5px solid rgba(159, 158, 159, 0.5)'>
              <Button
                onClick={onClose}
                variant='none'
                bg='transparent'
                border='none'
                w='50%' h='100%'
                fontFamily='Poppins'
                color="rgba(255, 255, 255, 1)"
                fontWeight="500"
                fontSize="20px"
                lineHeight="22px"
              >Cancel</Button>
              <Divider orientation='vertical' w='0.5px' h='100%' bg='rgba(159, 158, 159, 0.5)' opacity='.2'/>
              <Button
                onClick={onDelete}
                // isLoading={isLoading}
                variant='none'
                bg='transparent'
                border='none'
                w='50%' h='100%'
                fontFamily='Poppins'
                color="rgba(90, 145, 247, 1)"
                fontWeight="500"
                fontSize="20px"
                lineHeight="22px"
              >Yes</Button>
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>


    </Box>
  );
};

export default RemoveCompany;