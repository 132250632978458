import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IStatistic, RootStatistic} from "../../../models/analytics";

interface IAnalyticsState {
  isLoading: boolean;
  isError: string
  analytics: IStatistic
}

const initialState: IAnalyticsState = {
  isError: '',
  isLoading: false,
  analytics: {by_date: 0, total: 0},
}

export const analyticsSlice = createSlice({
  name: "Analytics",
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true
    },
    getAnalyticsSuccess(state, action: PayloadAction<RootStatistic>) {
      state.isLoading = false;
      state.analytics = action.payload
      state.isError = ""
    },
    getAnalyticsError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.isError = action.payload
    },
  }
})

export const AnalyticsReducer = analyticsSlice.reducer
