import { Box, Skeleton, Text } from "@chakra-ui/react";
import React, { Component } from "react";
import ProfileTickSvg from "../../assests/svg/ProfileTickSvg";

//local

interface IProps {
  text: string;
  quantity: number;
  isLoading: boolean;
}

class SmallRectangleQuantity extends Component<IProps> {
  render(): React.ReactNode {
    const { quantity, text, isLoading } = this.props;
    return (
      <Box
        borderRadius="20px"
        bg="#5776B51A"
        p="1.2rem"
        display="flex"
        justifyContent="space-between"
        w="50%"
        m="0.6rem"
      >
        <Box w="80%">
          <Text fontWeight="400" fontSize="14px" color="#A2D6FC">
            {text}
          </Text>
          {isLoading ? (
            <Skeleton w="60%" h="21px" mt="15px" />
          ) : (
            <Text fontWeight="500" fontSize="20px" mt="8px">
              {quantity}
            </Text>
          )}
        </Box>
        <ProfileTickSvg />
      </Box>
    );
  }
}

export default SmallRectangleQuantity;
