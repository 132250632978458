import React, {useState} from "react";

export function useFileDecoder() {
  const [files, setFiles] = useState<File[]>([])
  const [file, setFile] = useState<any>('')
  const fileReader: FileReader = new FileReader()


  const handleChange = (e: React.ChangeEvent<any>) => {

    const fileArr: File[] = e.target.files
    if (!fileArr.length) {
      return;
    }
    setFiles(Array.from(fileArr))
    const fileObj = fileArr[0];
    if (!fileObj) {
      return;
    }
    fileReader.onload = ev => {
      const src = ev.target?.result
      setFile(src)
    }
    fileReader.readAsDataURL(fileObj)
  }
  const removeImage = (index: number) => {
    setFiles(files.filter((_, idx) => idx !== index))
  }

  return [handleChange, file, files, removeImage]
}


export function useFileReader(setFile: (file: File) => void) {
  const [url, setUrl] = useState<any>('')
  const [fileObj, setFileObj] = useState<File>()
  const fileReader: FileReader = new FileReader()

  const handleChange = (e: React.ChangeEvent<any>) => {

    const file: File = e.target.files[0]
    if (!file) return;

    fileReader.onload = ev => {
      setFileObj(file)
      setFile(file)
      setUrl(ev.target?.result)
    }

    fileReader.readAsDataURL(file)
  }

  return [handleChange, fileObj, url]
}