import React from 'react';

const ProfileSvg = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3801 13.8458C13.3043 13.835 13.2068 13.835 13.1201 13.8458C11.2134 13.7808 9.69678 12.2208 9.69678 10.3033C9.69678 8.3425 11.2784 6.75 13.2501 6.75C15.2109 6.75 16.8034 8.3425 16.8034 10.3033C16.7926 12.2208 15.2868 13.7808 13.3801 13.8458Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.5516 20.9959C18.6232 22.7618 16.0666 23.8343 13.2499 23.8343C10.4332 23.8343 7.87658 22.7618 5.94824 20.9959C6.05658 19.9776 6.70658 18.9809 7.86574 18.2009C10.8341 16.2293 15.6874 16.2293 18.6341 18.2009C19.7932 18.9809 20.4432 19.9776 20.5516 20.9959Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.2501 23.8341C19.2332 23.8341 24.0834 18.9839 24.0834 13.0008C24.0834 7.01773 19.2332 2.16748 13.2501 2.16748C7.267 2.16748 2.41675 7.01773 2.41675 13.0008C2.41675 18.9839 7.267 23.8341 13.2501 23.8341Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default ProfileSvg;