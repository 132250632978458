import React from 'react';
import InterfaceHeader from "../../components/Navigations/InterfaceHeader";
import {Box} from "@chakra-ui/react";
import EditCompany from "./edit-company";
import EditProfile from "./edit-profile";
import {useAppSelector} from "../../config/hooks/redux";

const Profile = () => {
  const {isLoading} = useAppSelector(s => s.PostProfileReducer)
  return (
    <Box w='100%' pb='164px'>
      <InterfaceHeader
        isLoading={isLoading}
        title={"Profile"}
        pathToBack='/profile'
      />
      <EditProfile/>
      <EditCompany/>
    </Box>
  );
};

export default Profile;