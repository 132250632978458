import React, {useEffect, useState} from 'react';
import ProfileData from "./ProfileData";
import CompanyData from "./company/CompanyData";
import {Box} from "@chakra-ui/react";
import FixedButtons from "./FixedButtons";
import CompanyDetails from "./company/CompanyDetails";
import {INTERFACE_PADDING_X} from "../../config/_variables";
import Products from "./company/Products";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";
import {useParams} from "react-router-dom";
import {getUserById} from "../Profile/edit-profile/ProfileReducer/actions";
import Youtube from "./company/youtube";

const Home = () => {
  const [isFixed, setFixed] = useState(false)
  const dispatch = useAppDispatch()
  const {uid} = useParams()
  const {companies} = useAppSelector(s => s.CompanyReducer)
  window.addEventListener('scroll', () => {
    setFixed(window.scrollY > 348)
  })

  useEffect(() => {
    if (uid) {
      dispatch(getUserById(uid))
    }
  }, [uid, dispatch])
  return (
    <Box pb='75px'>
      <ProfileData isFixed={isFixed}/>
      {!!companies.length && <Box px={INTERFACE_PADDING_X}>
        <CompanyData/>
        <CompanyDetails/>
        <Youtube/>
        <Products/>
      </Box>}
      <FixedButtons/>
    </Box>
  );
};

export default Home;