import React from 'react';
import {Box} from "@chakra-ui/react";
import {Route, Routes} from "react-router-dom";
import {privateRoutes, publicRoutes, routesAdmin} from "./routes";
import ProtectedInterfacePage from "./ProtectedInterfacePage";
import {InterfacePages} from "../pages";
import {Toaster} from "react-hot-toast";
import NotFoundPage from "../components/NotFoundPage";
import ProtectAdminPage from "./ProtectAdminPage";
import Admin from "../admin";

const RoutesRender = () => {
  return (
    <Box bg='rgba(0, 0, 0, 0.9)'>
      <Toaster position="top-center" reverseOrder={true} />
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<InterfacePages>{route.element}</InterfacePages>}
            />
          ))}
        </Route>

        <Route element={<ProtectedInterfacePage/>}>
          {privateRoutes.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<InterfacePages>{route.element}</InterfacePages>}
            />
          ))}
        </Route>
        <Route element={<ProtectAdminPage/>}>
          {routesAdmin.map((route, idx) => (
            <Route
              key={idx}
              path={route.path}
              element={<Admin>{route.element}</Admin>}
            />
          ))}
        </Route>
        <Route path="*" element={<NotFoundPage/>} />
      </Routes>
    </Box>
  );
};

export default RoutesRender;