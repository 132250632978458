import React, {useState} from 'react';
import PlusButton from "../../../../../components/ui/Buttons/PlusButton";
import Placement from "../../../../../components/ui/placement";
import ProfileInput from "../../../../../components/ui/inputs/ProfileInput";
import {Box, useDisclosure} from "@chakra-ui/react";
import DefButton from "../../../../../components/ui/Buttons/DefButton";
import {IVideo} from "../../../../../models/company";
import {IoLinkSharp} from "react-icons/io5";
import {PiYoutubeLogo} from "react-icons/pi";
import {useAppDispatch} from "../../../../../config/hooks/redux";
import {addVideo} from "../../CompanyReducer/youtube.actions";

const AddVideo = () => {
  const dispatch = useAppDispatch()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [value, setValue] = useState<IVideo>({
    title: "",
    link: "",
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(addVideo(value))
  }


  return (
    <>
      <PlusButton
        name={"YouTube Video"}
        onClick={onOpen}
        icon={
          <Box color='white'
               fontSize='1.6rem'
          ><PiYoutubeLogo/>
          </Box>}
      />

      <Placement isOpen={isOpen} onClose={onClose} title={"Location"}>
        <form onSubmit={onSubmit}>
          <ProfileInput
            handleChange={handleChange}
            value={value.title}
            name={"title"}
            placeholder={"Title"}
            required={true}
          />
          <ProfileInput
            handleChange={handleChange}
            value={value.link}
            name={"link"}
            placeholder={"Video Link"}
            type='link'
            required={true}
            leftIcon={<IoLinkSharp/>}
          />
          <Box mt='40px'>
            <DefButton>{'SAVE'}</DefButton></Box>
        </form>
      </Placement>
    </>
  );
};

export default AddVideo;