import React, {useState} from 'react';
import PlusButton from "../../../../components/ui/Buttons/PlusButton";
import Placement from "../../../../components/ui/placement";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Progress,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import {BsDot} from "react-icons/bs";
import DefButton from "../../../../components/ui/Buttons/DefButton";
import AddPhotoButton from "./AddPhotoButton";
import {useFileDecoder} from "../../../../config/hooks/file-decoder";
import {formatBytes} from "../../../../config/helpers";
import {FaTrash} from "react-icons/fa";
import ProfileInput from "../../../../components/ui/inputs/ProfileInput";
import toast from "react-hot-toast";
import {uploadImages} from "./reducer/actions";
import {useAppDispatch} from "../../../../config/hooks/redux";
import {IGallery} from "../../../../models/company";

const AddPhotos = () => {
  const dispatch = useAppDispatch()
  const {isOpen, onOpen, onClose} = useDisclosure()
  const [isLoading, setLoad] = useState(false)
  const [handleChangeFile, url, files, removeImage] = useFileDecoder()

  const [value, setValue] = useState<IGallery>({
    title: "",
    images: []
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onsubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (!files.length){
      toast("please upload a photo", {icon: '⚠'})
    }else {
      dispatch(uploadImages(files, value, onClose))
    }
  }

  return (
    <>
      <PlusButton onClick={onOpen} name={"Add Photos"}/>

      <Placement isOpen={isOpen} onClose={onClose} title={'Gallery'}>
        <form onSubmit={onsubmit}>
          <Box mb='20px'>
            <ProfileInput
              handleChange={handleChange}
              value={value.title}
              required={false}
              name={"title"}
              placeholder={"Title"}
            />
          </Box>
          {files?.map((file: any, idx: number) => (
            <Box mb ={5} ml={2} key={idx}>
              <Flex key={idx} h='41.25px' w='100%' justifyContent='space-between' alignItems='center'>
                <Flex alignItems='center' w='85%'>
                  <Flex flexDirection='column' w='100%'>
                    <Text fontFamily='Poppins' w='100%' fontWeight='400' fontSize='18px' lineHeight='27px'
                          color='#FFFFFF'
                          noOfLines={1}>{file.name}</Text>
                    <Breadcrumb separator={<BsDot/>} fontFamily='Inter' fontWeight='400' fontSize='13.75px'
                                letterSpacing='0.69px' lineHeight='27.5px' color='#989898'>
                      <BreadcrumbItem><BreadcrumbLink
                        href='#'>{file.type.split('/')[0]}</BreadcrumbLink></BreadcrumbItem>
                      <BreadcrumbItem><BreadcrumbLink
                        href='#'>{formatBytes(file.size)}</BreadcrumbLink></BreadcrumbItem>
                      <BreadcrumbItem><BreadcrumbLink
                        href='#'>{file.type.split('/')[1]}</BreadcrumbLink></BreadcrumbItem>
                    </Breadcrumb>
                  </Flex>
                </Flex>
                <Button
                  onClick={() => removeImage(idx)}
                  w='37px' h='37px' bg='transparent' color='#D92C41'
                  p='9px 10px' fontSize='2rem'
                ><FaTrash/></Button>
              </Flex>
              {isLoading && <Progress size='xs' bg='none' isIndeterminate/>}
            </Box>
          ))}
          <Flex pt={'19px'}>
            <AddPhotoButton handleChange={handleChangeFile}/>
            <Box w='18px'/>
            <DefButton
              isLoading={isLoading}
              type={"submit"}
            >SAVE</DefButton>
          </Flex>
        </form>
      </Placement>
    </>
  );
};

export default AddPhotos;