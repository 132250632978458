import React, {useState} from 'react';
import {Box, Button, Progress, useDisclosure} from "@chakra-ui/react";
import {buttons_theme} from "../../../config/colors";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import Placement from "../../ui/placement";
import DefButton from "../../ui/Buttons/DefButton";
import {IContactButton} from "../../../models/custom";
import ProfileInput from "../../ui/inputs/ProfileInput";
import AddFileInput from "../../ui/inputs/AddFileInput";
import toast from "react-hot-toast";
import {uploadFiles} from "../../uploader/UploadFile-Reducer/actions";


interface Props2 {
  btn: IContactButton,
  onSubmit: (btn: IContactButton) => void
}

const CustomButton = ({btn, onSubmit}: Props2) => {
  const {onClose, isOpen, onOpen} = useDisclosure()
  const {def_theme} = useAppSelector(state => state.DesignReducer)
  const dispatch = useAppDispatch()
  const [value, setValue] = useState("")
  const {isLoading} = useAppSelector(state => state.CustomReducer)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  const handleClick = () => {
    if (!!value.trim().length) {
      onSubmit({...btn, value})
    } else {
      toast.error('Fill in the field')
    }
  }

  const handleChangeFile = (e: React.ChangeEvent<any>) => {
    const file: File = e.target.files[0]
    if (!file) return;
    dispatch(uploadFiles(file, setValue, () => {}))
  }

  return (
    <div>
      <Button
        onClick={onOpen}
        rounded='14px'
        variant='none'
        w='100%' h='42px' mb='2.5'
        bg={buttons_theme[def_theme]}
        fontWeight='500' fontSize='16px'
        color='rgba(255, 255, 255, 1)'
        fontFamily='Poppins'
        justifyContent='space-between'
      >{btn.name}
        <Box>
          {btn.icon}
        </Box>
      </Button>

      <Placement isOpen={isOpen} onClose={onClose} title={btn.name}>
        <Box>
          {btn.type_input !== 'file' &&
            <ProfileInput
              handleChange={handleChange}
              value={value}
              required={true}
              type={btn.type_input}
              leftIcon={btn.icon}
              name={btn.name.toLowerCase()}
              placeholder={btn.placeholder}
            />}


          {btn.type_input === 'file' &&
            <AddFileInput
              handleChange={handleChangeFile}
              value={value}
              placeholder={btn.placeholder}
              accept={['.pdf', '.word']}
            />}

          <Box mt='44px'>
            <DefButton onClick={handleClick} isLoading={isLoading} type='button'>Save</DefButton>
          </Box>

        </Box>
      </Placement>
    </div>
  );
};

export default CustomButton;