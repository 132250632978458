import React, {useState} from 'react';
import {Box, Modal, ModalContent} from "@chakra-ui/react";
import ModalInput from "../modalInput";
import EmailAdminSvg from "../modalInput/EmailAdminSvg";
import LockAdminSvg from "../modalInput/LockAdminSvg";
import {useNavigate} from "react-router-dom";
import DefButton from "../../components/ui/Buttons/DefButton";
import {loginSlice} from "../../components/forms/login/reducer/slice";
import {PUBLIC_API} from "../../api";
import {toastCreator} from "../../config/toast";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";

const AdminLogin = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {isLoading} = useAppSelector(s => s.LoginReducer)
  const [value, setValue] = useState({
    uid: "admin",
    password: ""
  })
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onLogin(value)
  }

  const onLogin = (value: any) => {
    dispatch(loginSlice.actions.setLoading())
    PUBLIC_API.post("auth/jwt/create/", value)
      .then(({data}) => {
        sessionStorage.setItem("accessTkn", data.access);
        sessionStorage.setItem("refreshTkn", data.refresh);
        dispatch(loginSlice.actions.onLogin(null));
        navigate("/admin");
        window.location.reload()
      })
      .catch((e) => {
        toastCreator("error", e.response?.data?.detail || "Error");
        dispatch(loginSlice.actions.onLogin(e.response?.data?.detail));
      });
  }
  return (
    <>
      <Modal blockScrollOnMount={false} isOpen={true} onClose={() => {
      }} isCentered={true} size='xl'>
        <ModalContent bg='#0E0E0E' px={{md: '53px', base: '4'}} py='66px' rounded='31px'>
          <form onSubmit={onSubmit}>
            <ModalInput
              handleChange={handleChange}
              value={value.uid}
              type={'email'}
              name={'email'}
              icon={<EmailAdminSvg/>}
              placeholder={"Email"}
              readonly={true}
            />
            <ModalInput
              handleChange={handleChange}
              value={value.password}
              type={'password'}
              name={'password'}
              icon={<LockAdminSvg/>}
              placeholder={"Password"}
            />
            <Box mt='16px'>
              <DefButton bg={"rgba(10, 132, 255, 1)"} isLoading={isLoading}>Login</DefButton>
            </Box>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AdminLogin;
