import React from 'react';
import {Box, Flex, Modal, ModalContent, ModalOverlay, useDisclosure} from "@chakra-ui/react";
import DefButton from "../../components/ui/Buttons/DefButton";
import UserCreatePage from "../UserCreatePage";

const AddUserBtn = () => {
  const {onOpen, isOpen, onClose} = useDisclosure()
  return (
    <Flex justifyContent='center' alignItems='center' h='100%'>
      <Box w='400px' mt='250px'>
        <DefButton
          onClick={onOpen}
          type='button'
          bg={"rgba(10, 132, 255, 1)"}
        >ADD USER</DefButton>
      </Box>


      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size='3xl'>
        <ModalOverlay/>
        <ModalContent
          bg='rgba(226, 226, 226, 1)'
          rounded='14px' px={8} py={10}>
          <UserCreatePage/>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default AddUserBtn;