import React from 'react';

const FacebookSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.8334 17.5392C23.8334 21.4825 21.4826 23.8333 17.5392 23.8333H16.2501C15.6542 23.8333 15.1667 23.3458 15.1667 22.75V16.4992C15.1667 16.2067 15.4051 15.9575 15.6976 15.9575L17.6042 15.925C17.7559 15.9142 17.8859 15.8058 17.9184 15.6542L18.2976 13.585C18.3301 13.39 18.1784 13.2058 17.9726 13.2058L15.6651 13.2383C15.3617 13.2383 15.1234 13 15.1126 12.7075L15.0693 10.0533C15.0693 9.88 15.2101 9.72834 15.3942 9.72834L17.9942 9.685C18.1784 9.685 18.3193 9.54418 18.3193 9.36001L18.2759 6.75999C18.2759 6.57582 18.1351 6.435 17.9509 6.435L15.0259 6.47834C13.2276 6.51084 11.7976 7.98416 11.8301 9.7825L11.8842 12.7617C11.8951 13.065 11.6568 13.3033 11.3534 13.3142L10.0534 13.3358C9.86925 13.3358 9.72843 13.4766 9.72843 13.6608L9.76092 15.7192C9.76092 15.9033 9.90174 16.0442 10.0859 16.0442L11.3859 16.0225C11.6893 16.0225 11.9276 16.2608 11.9384 16.5533L12.0359 22.7283C12.0467 23.335 11.5592 23.8333 10.9526 23.8333H8.46091C4.51758 23.8333 2.16675 21.4825 2.16675 17.5283V8.46083C2.16675 4.51749 4.51758 2.16666 8.46091 2.16666H17.5392C21.4826 2.16666 23.8334 4.51749 23.8334 8.46083V17.5392Z" fill="white"/>
    </svg>
  );
};

export default FacebookSvg;