import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {DesignReducer} from "../pages/Design/DesignReducer/slice";
import {ChangePasswordReducer} from "../pages/ResetLink/reducer/slice";
import {LoginReducer} from "../components/forms/login/reducer/slice";
import {PostProfileReducer} from "../pages/Profile/edit-profile/ProfileReducer/postProfile.slice";
import {ProfileReducer} from "../pages/Profile/edit-profile/ProfileReducer/profileSlice";
import {CompanyReducer} from "../pages/Profile/edit-company/CompanyReducer/slice";
import {ForgotPasswordReducer} from "../components/forms/forgot-password/reducer/slice";
import {PostLeadReducer} from "../pages/Leads/reducer/postLeads";
import {LeadsReducer} from "../pages/Leads/reducer/slice";
import {CustomReducer} from "../components/custom-buttons/CustomReducer/slice";
import {SaveContactReducer} from "../pages/Home/FixedButtons/SaveContact/reducer/slice";
import {AnalyticsReducer} from "../pages/Analytics/reducer/slice";
import {GalleryReducer} from "../pages/Profile/edit-company/AddPhotos/reducer/gallery.slice";
import {UploadFileReducer} from "../components/uploader/UploadFile-Reducer/upload.slice";

const rootReducer = combineReducers({
  DesignReducer,
  LoginReducer,
  ForgotPasswordReducer,
  ChangePasswordReducer,
  PostProfileReducer,
  ProfileReducer,
  CompanyReducer,
  LeadsReducer,
  PostLeadReducer,
  CustomReducer,
  SaveContactReducer,
  AnalyticsReducer,
  GalleryReducer,
  UploadFileReducer
});


const setupStore = () => {
  return configureStore({
    reducer: rootReducer,
    devTools: false,
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware().concat(shopApi.middleware),
  });
};

export const store = setupStore()

export type RootState = ReturnType<typeof rootReducer>;
type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];

