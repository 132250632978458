import React from 'react';
import PlusButton from "../../../../components/ui/Buttons/PlusButton";
import {useDisclosure} from "@chakra-ui/react";
import CustomModal from "../../../../components/custom-buttons/CustomModal";
import {IContactButton} from "../../../../models/custom";
import {addCustom} from "../../../../components/custom-buttons/CustomReducer/actions";
import {useAppDispatch, useAppSelector} from "../../../../config/hooks/redux";

const AddButtons = () => {
  const {onOpen, onClose, isOpen} = useDisclosure()
  const {company} = useAppSelector(s => s.CompanyReducer)
  const dispatch = useAppDispatch()
  const onSubmit = (btn: IContactButton) => {
    dispatch(addCustom(btn, 0, company.id, onClose))
  }
  return (
    <>
      <PlusButton onClick={onOpen} name={"Add Buttons"}/>
      <CustomModal onClose={onClose} isOpen={isOpen} onSubmit={onSubmit}/>
    </>
  );
};

export default AddButtons;