// export const DEFAULT_THEME = "rgba(255, 255, 255, 1)"

// color
export const DARK_SYSTEM_COLOR = "#FFFFFFFF"

export const LIGHT_SYSTEM_COLOR = "#090909FF"

// theme

export const DARK_THEME = LIGHT_SYSTEM_COLOR

export const LIGHT_THEME = DARK_SYSTEM_COLOR

export const main_theme = {
  DARK: LIGHT_SYSTEM_COLOR,
  LIGHT: DARK_SYSTEM_COLOR
}

export const main_color_theme = {
  DARK: DARK_SYSTEM_COLOR,
  LIGHT: LIGHT_SYSTEM_COLOR
}


export const box_theme = {
  DARK: "rgba(49, 51, 53, 0.7)",
  LIGHT: "rgba(226, 226, 226, 1)",
}

export const buttons_theme = {
  DARK: "rgba(255, 255, 255, 0.4)",
  LIGHT: "rgba(0, 0, 0, 0.5)",
}

export const inputs_theme = {
  DARK: "rgba(29, 29, 29, 1)",
  LIGHT: "rgba(229, 229, 229, 1)",
}

export const add_btn_theme = {
  DARK: "rgba(37, 37, 40, 1)",
  LIGHT: "rgba(0, 0, 0, 0.4)",
}

export const placement_theme = {
  DARK: "rgba(49, 51, 53, 0.7)",
  LIGHT: "rgba(255, 255, 255, 0.8)",
}







export const SYSTEM_FONT = "Poppins"

export const KEY_THEME: string = "interface-theme"

