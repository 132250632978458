import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface IloginState {
  isOpenLoginForm: boolean;
  isLoading: boolean;
  isError: string | null
}

const initialState: IloginState = {
  isError: null,
  isLoading: false,
  isOpenLoginForm: false
}

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoading(state){
      state.isLoading = true
    },
    onLogin(state, action: PayloadAction<string | null>){
      state.isLoading = false;
      state.isError = action.payload
    },
    onToggleLoginForm(state, action: PayloadAction<boolean>){
      state.isOpenLoginForm = action.payload
    },
  }
})

export const LoginReducer = loginSlice.reducer
export const {onToggleLoginForm} = loginSlice.actions