import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ILeadsState {
  isLoading: boolean;
  isError: string | null
  leads: any[]
}

const initialState: ILeadsState = {
  isError: null,
  isLoading: false,
  leads: []
}

export const leadsSlice = createSlice({
  name: "post/leads",
  initialState,
  reducers: {
    setLoading(state){
      state.isLoading = true
    },
    getLeads(state, action: PayloadAction<any[]>){
      state.isLoading = false;
      state.leads = action.payload
      state.isError = null
    },
    getLeadsError(state, action: PayloadAction<string>){
      state.isLoading = false;
      state.isError = action.payload
    }
  }
})

export const LeadsReducer = leadsSlice.reducer