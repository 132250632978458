import {gallerySLice} from "./gallery.slice";
import toast from "react-hot-toast";
import {IGallery} from "../../../../../models/company";
import PRIVATE_API, {API_ADDRESS} from "../../../../../api";
import {AppDispatch} from "../../../../../redux/store";
import {uploadSlice} from "../../../../../components/uploader/UploadFile-Reducer/upload.slice";
import {toastCreator} from "../../../../../config/toast";
import {getCompany} from "../../CompanyReducer/action";

export const uploadImages = (files: File[], value: IGallery, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(uploadSlice.actions.uploadFile())
    const config = {
      headers: {'content-type': 'multipart/form-data'}
    }
    Promise.all(files.map(async (file) => {
      let formData = new FormData()
      formData.append('file', file)
      const res =  await PRIVATE_API.post('api/upload/', formData, config)
      return API_ADDRESS + res.data.file
    })).then(images => {
      dispatch(addGallery({...value, images}, onClose))
    }).catch(e => {
      dispatch(uploadSlice.actions.uploadFileError(e.message))
    }).then(() => {
      dispatch(uploadSlice.actions.uploadFileSuccess())
    })
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(uploadSlice.actions.uploadFileError(e.response?.data?.message))
  }
}


export const addGallery = (value: IGallery, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(gallerySLice.actions.addGallery())
    const response = value.id ?
      await PRIVATE_API.put(`api/gallery/${value.id}/`, value) :
      await PRIVATE_API.post('api/gallery/', value)
    dispatch(gallerySLice.actions.addedGallery(null))
    toastCreator('success')
    dispatch(getCompany())
    onClose()
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    dispatch(gallerySLice.actions.addedGallery(e.message))
  }
}


export const removeGalley = (id: number) => async (dispatch: AppDispatch) => {
  try {
    await PRIVATE_API.delete(`api/gallery/${id}/`)
    dispatch(getCompany())
    toast.success('Images deleted')
  } catch (e: any) {
    toastCreator('error', e.response?.data?.message)
  }
}