export const NAVBAR_HEIGHT = "83px"

export const SAVE_BTN_HEIGHT = "89px"

export const HEADER_HEIGHT = "44px"

export const INTERFACE_WIDTH = "576px"

export const INTERFACE_PADDING_X = "5px"

export const INNER_WIDTH = window.innerWidth






export const FONTS: string[] =
  [
    'Aldrich',
    'Sue Ellen Francisco',
    'Poppins',
    'Comfortaa',
    'Inter',
    'Josefin Sans',
    'Niconne',
    'Cormorant Garamond',
    'Codystar',
    'Coiny',
    'Anton',
    'Emblema One',
    'Monoton',
    'Train One',
    'Allerta Stencil'
  ]