import React from 'react';
import {IGallery, IVideo} from "../../../../models/company";
import {add_btn_theme} from "../../../../config/colors";
import {Box, Flex, Text} from "@chakra-ui/react";
import {HiMinusSm} from "react-icons/hi";
import {useAppDispatch, useAppSelector} from "../../../../config/hooks/redux";
import {removeButton} from "../../../../components/custom-buttons/CustomReducer/actions";
import {removeGalley} from "./reducer/actions";

interface Props {
  gallery: IGallery
}
const GalleryCard = ({gallery}: Props) => {
  const {def_theme} = useAppSelector(state => state.DesignReducer)
  const dispatch = useAppDispatch()
  const deleteButton = () => {
    dispatch(removeGalley(Number(gallery.id)))
  }
  return (
    <Flex
      justifyContent='space-between' h='58px'
      rounded='8px' bg={add_btn_theme[def_theme]}
      pr='9px' pt='6px' pb='8px' pl='15px'
    >
      <Flex
        flexDirection='column'
        justifyContent='space-between' h='100%'
      >
        <Box noOfLines={1} fontSize='11px' mr='11px' fontWeight='400' lineHeight='12px' color='#FFFFFF' >{gallery.title}</Box>
        <Flex  justifyContent='space-between' alignItems='end'>
          <Text
            fontFamily='Poppins'
            fontWeight='500'
            fontSize='16px'
            lineHeight='22px'
            letterSpacing='0.07px'
            color='rgba(255, 255, 255, 1)'
          >Photos:</Text>
          <Text
            ml='25%'
            fontFamily='Poppins'
            fontWeight='500'
            fontSize='16px'
            lineHeight='22px'
            letterSpacing='0.07px'
            color='rgba(255, 255, 255, 1)'
          >{gallery.images.length}</Text>
        </Flex>
      </Flex>

      {/*<Box*/}
      {/*  fontSize='1.2rem' cursor='pointer'*/}
      {/*  color='rgba(229, 229, 229, 1)'*/}
      {/*  _active={{opacity: '.7'}}><RiEditBoxLine/></Box>*/}
      <Flex
        onClick={deleteButton}
        justifyContent='center' alignItems='center'
        color='#D9D9D9' bg='#DC2626' w='19.9px' h='20px'
        rounded='50%' fontSize='1rem'
        _active={{opacity: 0.7}} cursor='pointer'
      ><HiMinusSm/>
      </Flex>
    </Flex>
  );
};

export default GalleryCard;