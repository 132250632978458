import React from 'react';
import {Outlet} from "react-router-dom";
import Admin from "../admin";
import AdminLogin from "../admin/AdminLogin";
import {useActiveAuth} from "../config/hooks/useActiveAuth";

const ProtectAdminPage = () => {
  const isAuth = useActiveAuth()
  return isAuth ? <Outlet/> : <Admin><AdminLogin/></Admin>
};

export default ProtectAdminPage;