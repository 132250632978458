import React, {useEffect, useState} from 'react';
import {Box, Button, Modal, ModalContent, ModalOverlay, Text, useDisclosure} from "@chakra-ui/react";
import MenuItemUi from "../../../pages/Home/ProfileData/MenuBtn/MenuItemUi";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {sendToEmail} from "./reducer/action";
import Placement from "../../ui/placement";
import ProfileInput from "../../ui/inputs/ProfileInput";
import DefButton from "../../ui/Buttons/DefButton";
import {onToggleChangePassword} from "../../../pages/ResetLink/reducer/slice";
import {useAuthUser} from "../../../config/hooks/userAccess";

interface Props {
  nameBtn: string;
  icon: JSX.Element
}
const ForgotPassword = ({nameBtn, icon}: Props) => {
  const dispatch = useAppDispatch()
  const {onOpen, onClose, isOpen} = useDisclosure()
  const {user} = useAppSelector(s => s.ProfileReducer)
  const {system_color} = useAppSelector(state => state.DesignReducer)
  const isAuth = useAuthUser()
  const {isOpenChangePassword} = useAppSelector(state => state.ChangePasswordReducer)
  const {isLoading} = useAppSelector(state => state.ForgotPasswordReducer)
  const [email, setEmail] = useState(isAuth ? user.email : "")
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.target.value)
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(sendToEmail(email, onOpen))
  }

  useEffect(() => {
    if (isAuth){
      setEmail(user.email)
    }
  }, [user.email])
  return (
    <>
      <MenuItemUi name={nameBtn} icon={icon} onClick={() => dispatch(onToggleChangePassword(true))}/>

      <Placement
        isOpen={isOpenChangePassword}
        onClose={() => dispatch(onToggleChangePassword(false))}
        title={"Email"}>
        <form onSubmit={onSubmit}>
          <ProfileInput
            handleChange={handleChange}
            value={email} required={true}
            type='email' name={'email'}
            placeholder={"user@example.com"}/>
          <Text
            fontSize='11px' letterSpacing='0.07 px' px='6px'
            fontWeight='400' fontFamily={"Poppins"}
            lineHeight='12px' color={system_color} opacity='.6'
          >Write the email address that was linked to this platform.</Text>
          <Box mt='37px'>
            <DefButton isLoading={isLoading}>Send</DefButton>
          </Box>
        </form>
      </Placement>

      <Modal isOpen={isOpen} onClose={() => {
      }} size={{sm: 'lg', base: "sm"}} isCentered={true}>
        <ModalOverlay bg='rgba(0, 0, 0, 0.7)'/>
        <ModalContent
          bg='rgba(49, 51, 53, 0.7)'
          px='20px' py='50px' rounded='20px'

        >
          <Text
            fontFamily='Poppins'
            fontWeight='400'
            fontSize='16px'
            lineHeight='19.09px'
            textAlign='center'
            color='rgba(255, 255, 255, 1)'
          >A link to reset your password has been sent to your email address, please check.</Text>
          <Button
            onClick={() => {
              onClose()
              dispatch(onToggleChangePassword(false))
            }}
            variant='none'
            bg='transparent'
            fontFamily='Poppins'
            fontWeight='500' w='50%'
            fontSize='20px' mt='28px'
            lineHeight='23.87px' mx='auto'
            color='rgba(186, 156, 87, 1)'
            _hover={{bg: 'rgba(49, 51, 53, 0.8)'}}
          >OK</Button>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ForgotPassword;