import React from 'react';
import RoutesRender from "./routes/routes-render";
import {Box} from "@chakra-ui/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const App = () => {
  return (
    <Box>
      <RoutesRender/>
    </Box>
  );
};

export default App;