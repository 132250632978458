import React, {useEffect, useState} from 'react';
import InterfaceHeader from "../../components/Navigations/InterfaceHeader";
import {Box, Button, Divider, Flex, Heading, Select, SimpleGrid, Text} from "@chakra-ui/react";
import {MdOutlinePhoneIphone} from "react-icons/md";
import {BsFillTelephoneFill} from "react-icons/bs";
import {IoIosMail, IoLogoWhatsapp} from "react-icons/io";
import DesignCard from "./DesignCard";
import {PiSunDim} from "react-icons/pi";
import {BiSolidMoon, BiSolidShare} from "react-icons/bi";
import ColorPalette from "./color-palette";
import {KEY_THEME, DARK_SYSTEM_COLOR, box_theme, LIGHT_SYSTEM_COLOR, main_color_theme} from "../../config/colors";
import {hexToRGB} from "../../config/helpers";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";
import {changeColor, changeTheme, setFont, toSystemColor} from "./DesignReducer/slice";
import {FONTS} from "../../config/_variables";
import DefButton from "../../components/ui/Buttons/DefButton";
import {addTheme} from "./DesignReducer/actions";

const buttons = [
  {
    icon: <MdOutlinePhoneIphone/>,
    name: 'Mobile',
  },
  {
    icon: <BsFillTelephoneFill/>,
    name: 'Phone',
  },
  {
    icon: <IoLogoWhatsapp/>,
    name: 'Whatsapp',
  },
  {
    icon: <IoIosMail/>,
    name: 'Email',
  },
]


const Design = () => {
  const dispatch = useAppDispatch()
  const {def_color, colors, def_theme, def_font, system_color, isLoading} = useAppSelector(state => state.DesignReducer)
  const {user: {id: user_id, full_name, position}} = useAppSelector(state => state.ProfileReducer)

  const [currentColor, setDefColor] = useState(def_color)
  const saveThemeColor = (color: string) => {
    setDefColor(color)
    dispatch(changeColor(color))
  }

  const changeFont = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setFont(e.target.value))
  }

  const onSystem = () => {
    dispatch(toSystemColor())
    setDefColor(system_color)
  }

  const onSubmit = () => {
    dispatch(addTheme({
      dark_theme: def_theme === "DARK",
      color: def_color,
      font: def_font
    }, user_id))
  }

  useEffect(() => {
    setDefColor(def_color)
  }, [def_color])

  return (
    <Box pt='27px'>
      <InterfaceHeader
        title={"Design"}
        pathToBack='/design'
      />

      <Flex
        h='290px' rounded='16px'
        border={`1px solid ${hexToRGB(system_color, 0.3)}`}
        flexDirection='column' alignItems='center'
        pt='35px' pb='40px' w='100%' px='21px'
      >
        <Text
          fontFamily={def_font}
          lineHeight='28px'
          color={system_color}
          fontSize='22px'
          fontWeight='700'
          letterSpacing='0.35px'
        >{full_name || 'Full Name'}</Text>

        <Text
          fontFamily={def_font}
          lineHeight='16px'
          color={system_color}
          fontSize='13px'
          fontWeight='400'
          letterSpacing='0.35px'
        >{ position || "Designation"}</Text>

        <SimpleGrid
          mt={5} w='100%'
          columns={4}
          spacing='8px'
        >

          {buttons.map((btn) => (
            <Button
              key={btn.name}
              variant='none' p='0' h='58px'
              rounded='8px'
              flexDirection='column' alignItems='center'
              bg={hexToRGB(currentColor, 0.4)}
              color={main_color_theme[def_theme]}
            >
              <Box fontSize='1.4rem'>{btn.icon}</Box>
              <Text
                fontSize='11px' mt={1}
                color={main_color_theme[def_theme]}
                fontWeight='400'
                fontFamily='Poppins'
              >{btn.name}</Text>
            </Button>
          ))}
        </SimpleGrid>
        <Divider mt='25px' color={hexToRGB(currentColor, 0.2)} opacity='.2'/>
        <Text
          fontFamily='SF Pro Display'
          lineHeight='28px'
          color={currentColor}
          fontSize='22px' mt='40px'
          fontWeight='700'
          letterSpacing='0.35px'
        >Title</Text>
      </Flex>


      <Box
        bg={box_theme[def_theme]} rounded='16px'
        minH='331px' px='21px' pt='20px' pb='39px' my='14px'
      >

        <Select
          onChange={changeFont}
          variant='outline' bg='rgba(90, 90, 90, 1)' color='rgba(255, 255, 255, 1)'
          fontFamily='Poppins' fontSize='16px' rounded='10px' fontWeight='500'
          w='100%' h='42px' className='language-select unscroll' >
          {FONTS.map(font => (
            <option
              selected={font === def_font}
              key={font}
              style={{fontFamily: font}}
              defaultValue={font}
              value={font}>{font}</option>
          ))}
        </Select>

        <Box mt='50px' maxW='348' mx='auto'>

          <SimpleGrid columns={4} spacing={5} w='100%'>
            <ColorPalette setDefColor={setDefColor}/>
            <DesignCard
              onClick={() => dispatch(changeTheme("LIGHT"))}
              isActive={def_theme === "LIGHT"}>
              <Box fontSize='2rem'><PiSunDim/></Box>
            </DesignCard>
            <DesignCard
              onClick={() => dispatch(changeTheme("DARK"))}
              isActive={def_theme === "DARK"}>
              <Box fontSize='2rem'><BiSolidMoon/></Box>
            </DesignCard>
            <DesignCard
              onClick={onSystem}
              isActive={def_color === DARK_SYSTEM_COLOR || def_color === LIGHT_SYSTEM_COLOR}>
              <Flex flexDirection='column' alignItems='center'>
                <Box fontSize='2rem'><BiSolidShare/></Box>
                <Text fontFamily='SF Pro Text' fontSize='10px' letterSpacing='0.5px' fontWeight='400'>System</Text>
              </Flex>
            </DesignCard>
          </SimpleGrid>

          <Heading
            mt='30px'
            fontFamily='Poppins'
            lineHeight='22px'
            color='rgba(255, 255, 255, 1)'
            fontSize='16px'
            fontWeight='500'
            letterSpacing='-0.21px'
          >Templates</Heading>
          <SimpleGrid columns={4} spacing={5} mt={2}>
            {colors.map(color => (
              <Button
                onClick={() => saveThemeColor(color)}
                bg={color === currentColor ? color : 'transparent'}
                key={color}
                variant='none'
                w='67px' h='33px'
                rounded='12px'
                border={`1px solid ${color}`}
                fontFamily='Poppins'
                lineHeight='12px'
                color={color === currentColor ? main_color_theme[def_theme] : color}
                fontSize='11px'
                fontWeight='400'
              >Color</Button>
            ))}
          </SimpleGrid>
        </Box>
        <Box mt='44px'>
          <DefButton onClick={onSubmit} isLoading={isLoading} type='button'>Save</DefButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Design;