import React from 'react';

const LeadsSvg = () => {
  return (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.75 12.0009C15.6174 12.0009 17.9419 9.7625 17.9419 7.0013C17.9419 4.2401 15.6174 2.00171 12.75 2.00171C9.88259 2.00171 7.55811 4.2401 7.55811 7.0013C7.55811 9.7625 9.88259 12.0009 12.75 12.0009Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.83057 22.0002C3.83057 18.1305 7.82831 15.0007 12.7502 15.0007C13.7471 15.0007 14.7128 15.1307 15.6162 15.3707" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.1337 18.0004C23.1337 18.7503 22.9156 19.4603 22.5314 20.0602C22.3134 20.4202 22.033 20.7402 21.7111 21.0002C20.9842 21.6301 20.0289 22.0001 18.9802 22.0001C17.4641 22.0001 16.1454 21.2201 15.4289 20.0602C15.0447 19.4603 14.8267 18.7503 14.8267 18.0004C14.8267 16.7405 15.4289 15.6106 16.3842 14.8807C17.1007 14.3307 18.0041 14.0007 18.9802 14.0007C21.275 14.0007 23.1337 15.7906 23.1337 18.0004Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M17.3604 18.0005L18.3883 18.9904L20.6001 17.0206" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default LeadsSvg;