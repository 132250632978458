import React from 'react';
import {Box, Button, Flex, Image, SimpleGrid, Text} from "@chakra-ui/react";
import {INTERFACE_PADDING_X, INTERFACE_WIDTH} from "../../../config/_variables";
import MenuBtn from "./MenuBtn";
import {hexToRGB, navigateToUrl} from "../../../config/helpers";
import {useAppSelector} from "../../../config/hooks/redux";
import {IContactButton} from "../../../models/custom";
import Loader from "../../../components/Loader";
import {main_color_theme, main_theme} from "../../../config/colors";
import PersonGif from "../../../assests/gif/Person.gif"


const ProfileData = ({isFixed}: {isFixed: boolean}) => {
  const {user: {position, photo, full_name}, isLoading, buttons} = useAppSelector(s => s.ProfileReducer)
  const {def_theme, def_color, def_font} = useAppSelector(state => state.DesignReducer)
  const handleClick = (btn: IContactButton) => {
    navigateToUrl(btn.offer + btn.value.trim(), btn.type === "Phone");
  }
  return (
    <Box>
      {isLoading && <Loader/>}
      <Box w='100%' h='348px' position='relative'>
        <Image w='100%' h='100%' objectFit='cover' src={photo ? photo : PersonGif} alt=""/>
        <Box
          position='fixed' zIndex='100' right='0' left='0' top={'0'}
        >
          {<Flex
            bg={isFixed ? main_theme[def_theme] : 'none'}
            mx='auto' pt={'14px'} pb={1}
            maxW={INTERFACE_WIDTH}
            justifyContent='end'
            alignItems='center'
          >
            <MenuBtn/>
          </Flex>}
        </Box>
      </Box>

      <Flex flexDirection='column' alignItems='center' mt='18px' mb='30px'>
        <Text
          fontFamily={def_font}
          fontSize='22px'
          fontWeight='700'
          lineHeight='28px'
          color={def_color}
          letterSpacing='0.35 px'
        >{full_name}</Text>
        <Text
          fontFamily={def_font}
          fontSize='13px'
          fontWeight='400'
          lineHeight='16px'
          color={hexToRGB(def_color, 0.6)}
          // color='rgba(235, 235, 245, 0.6)'
        >{position}</Text>
      </Flex>

      <SimpleGrid
        px={INTERFACE_PADDING_X}
        columns={buttons.length <= 4 ? buttons.length : 4}
        spacing='8px'
      >
        {buttons.map((btn, idx) => (
          <Button
            onClick={() => handleClick(btn)}
            key={idx}
            variant='none' p='0' h='58px'
            rounded='8px'
            flexDirection='column' alignItems='center'
            bg={hexToRGB(def_color, 0.4)}
            color={def_color}
          >
            <Box fontSize='1.4rem' color={def_color}>{btn.icon}</Box>
            <Text
              fontSize='11px' mt={1}
              fontWeight='400'
              color={main_color_theme[def_theme]}
              fontFamily='Poppins'
            >{btn.name}</Text>
          </Button>
        ))}
      </SimpleGrid>
    </Box>
  );
};

export default ProfileData;