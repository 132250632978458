import React from 'react';
import {Button} from "@chakra-ui/react";
interface Props {
  children: string | JSX.Element;
  onClick?: () => void;
  type?: "button" | "submit";
  disabled?: boolean;
  bg?: string;
  isLoading?: boolean
}
const DefButton = ({onClick = () => {}, type = 'submit', disabled, children, isLoading,
                     bg = 'rgba(186, 156, 87, 1)'}: Props) => {
  return (
    <Button
      onClick={() => type === "button" && onClick()}
      type={type}
      variant='none'
      bg={bg}
      h={type === "button" ? '44px' : '50px'} w='100%'
      rounded='14px'
      color='rgba(255, 255, 255, 1)'
      fontSize='14px'
      fontWeight='600'
      lineHeight='22px'
      textTransform='uppercase'
      _hover={{bg}}
      isDisabled={disabled}
      isLoading={isLoading}
    >{children}</Button>
  );
};

export default DefButton;