import React from 'react';
import TitleText from "../../../../components/ui/text/TitleText";
import AddPhotos from "../AddPhotos";
import AddVideo from "./AddVideo";
import {Box, SimpleGrid} from "@chakra-ui/react";
import {useAppSelector} from "../../../../config/hooks/redux";
import VideoCard from "./AddVideo/VideoCard";
import GalleryCard from "../AddPhotos/GalleryCard";

const Gallery = () => {
  const {company: {videos, galleries}} = useAppSelector(s => s.CompanyReducer)
  return (
    <Box mt='45px'>
      <TitleText
        fontWeight='500'
        fontSize='16px'
        lineHeight='22px'
      >Gallery</TitleText>
      {<SimpleGrid columns={3} spacing='6px 8px'>
        {!!videos.length && videos.map((video, idx) => (
          <VideoCard video={video} key={idx}/>
        ))}
        {!!galleries.length && galleries.map((image, idx) => (
          <GalleryCard gallery={image} key={idx}/>
        ))}
      </SimpleGrid>}
      <AddPhotos/>
      <AddVideo/>
    </Box>
  );
};

export default Gallery;