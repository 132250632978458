import React, {useEffect, useState} from 'react';
import {Avatar, Box, Flex, SimpleGrid} from "@chakra-ui/react";
import ProfileInput from "../../../components/ui/inputs/ProfileInput";
import AddContactsBtn from "./AddContactsBtn";
import SaveChanges from "../../../components/ui/Buttons/SaveChanges";
import AvatarLoad from "./AvatarLoad";
import {addProfile} from "./ProfileReducer/actions";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {useFileDecoder} from "../../../config/hooks/file-decoder";
import Loader from "../../../components/Loader";
import EditButton from "../edit-company/AddButtons/EditButton";

const EditProfile = () => {
  const dispatch = useAppDispatch()
  const {user, isLoading, buttons} = useAppSelector(state => state.ProfileReducer)
  const [fn, url, files] = useFileDecoder()
  const [value, setValue] = useState({...user})
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmitProfile = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(addProfile(value))
  }
  useEffect(() => {
    setValue(user)
  }, [user])

  return (
    <>
      {isLoading && <Loader/>}
      <Flex justifyContent='center' pt='30px' pb='35px' w='100%'>
        <AvatarLoad/>
      </Flex>
      <form onSubmit={onSubmitProfile}>
        <ProfileInput
          handleChange={handleChange}
          value={value.full_name}
          name='full_name'
          placeholder={"Full name"}
          required={true}
        />
        <ProfileInput
          handleChange={handleChange}
          value={value.position}
          name='position'
          placeholder={"Position"}
          required={true}
        />
        <ProfileInput
          handleChange={handleChange}
          value={value.email}
          type='email'
          name='email'
          placeholder={"Email"}
          required={true}
        />
        <SimpleGrid columns={4} spacing={'10px 8px'} mt='15px'>
          {buttons.map(btn => (
            <EditButton btn={btn} key={btn.id}/>
          ))}
        </SimpleGrid>
        <Flex justifyContent='space-between' alignItems='center' mt={2}>
          <AddContactsBtn disabled={buttons.length > 3}/>
          <SaveChanges disabled={false}/>
        </Flex>
      </form>
    </>
  );
};

export default EditProfile;