import React from 'react';
import {Button} from "@chakra-ui/react";
import {buttons_theme} from "../../../config/colors";
import {useAppSelector} from "../../../config/hooks/redux";

interface Props {
  isActive: boolean;
  children: JSX.Element;
  onClick: () => void
}
const DesignCard = ({onClick, isActive, children} : Props) => {
  const {def_theme} = useAppSelector(state => state.DesignReducer)
  return (
    <Button
      onClick={onClick}
      variant='none'
      // bg={isActive ? "rgba(255, 255, 255, 0.3)": 'rgba(255, 255, 255, 0.14)'}
      bg={buttons_theme[def_theme]}
      opacity={isActive ? '1': '.6'}
      border={isActive ? "1px solid rgba(255, 255, 255, 0.4)": 'none'}
      h='67px' w='67px' rounded='20px'
      _active={{bg: "rgba(255, 255, 255, 0.3)"}}
      color='rgba(255, 255, 255, 1)'
    >
      {children}
    </Button>
  );
};

export default DesignCard;