import {RootStatistic, StatisticType} from "../../../models/analytics";
import {AppDispatch} from "../../../redux/store";
import PRIVATE_API from "../../../api";
import {analyticsSlice} from "./slice";


export const getAnalytics =
  (type: StatisticType, date: any) => async (dispatch: AppDispatch) => {
    try {
      dispatch(analyticsSlice.actions.setLoading());
      const { data } = await PRIVATE_API.get<RootStatistic>(
        `analytics/?type_=${type}&date=${date}`
        // `api/v1/users/statistics/?type_=${type}&date=${date}`
      );
      dispatch(analyticsSlice.actions.getAnalyticsSuccess(data));
    } catch (error: any) {
      dispatch(analyticsSlice.actions.getAnalyticsError(error.message));
    }
  };
