import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ISaveContactState {
  isLoading: boolean;
  isError: string | null
}

const initialState: ISaveContactState = {
  isError: null,
  isLoading: false,
}

export const saveContactSlice = createSlice({
  name: "save-contact",
  initialState,
  reducers: {
    setLoading(state){
      state.isLoading = true
    },
    onSavedContact(state, action: PayloadAction<string | null>){
      state.isLoading = false;
      state.isError = action.payload
    },
  }
})

export const SaveContactReducer = saveContactSlice.reducer