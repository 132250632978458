import React from 'react';
import {ColorPicker, useColor, Alpha, Hue, Saturation, IColor} from "react-color-palette";
import "react-color-palette/css";
import {Box, Button, Drawer, DrawerContent, DrawerOverlay, Flex, Input, useDisclosure} from "@chakra-ui/react";
import {INTERFACE_WIDTH} from "../../../config/_variables";
import DesignCard from "../DesignCard";
import {PiMagicWandDuotone} from "react-icons/pi";
import DefButton from "../../../components/ui/Buttons/DefButton";
import {KEY_THEME} from "../../../config/colors";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {addColors, changeColor} from "../DesignReducer/slice";

interface Props {
  setDefColor: (a: string) => void;
}


export const palette_theme = {
  DARK: "rgba(255, 255, 255, 1)",
  LIGHT: "rgba(71, 71, 71, 1)"
}


const ColorPalette = ({setDefColor}: Props) => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const dispatch = useAppDispatch()
  const {def_color, def_theme} = useAppSelector(state => state.DesignReducer)
  const [color, setColor] = useColor(def_color);

  const saveThemeColor = () => {
    dispatch(changeColor(color.hex))
    dispatch(addColors(color.hex))
    onClose()
  }

  const handleChange = (e: IColor) => {
    setColor(e)
    setDefColor(e.hex)
  }

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setColor({...color, hex: e.target.value})
    setDefColor(e.target.value)
  }
  const cancelPaint = () => {
    setDefColor(def_color)
    onClose()
  }
  return (
    <Box>
      <DesignCard onClick={onOpen} isActive={isOpen}>
        <Box fontSize='2rem'><PiMagicWandDuotone/></Box>
      </DesignCard>
      <Drawer isOpen={isOpen} onClose={() => null} placement={"bottom"}>
        <DrawerOverlay bg='none'/>
        <DrawerContent
          w={INTERFACE_WIDTH}
          maxH="80vh" mx="auto"
          bg='transparent'
          px='10px' pb='10px'
          borderTopRadius="16px"
        >
          <Box
            rounded="16px"
            w={'100%'}
            h='100%' p='7px'
            bg={palette_theme[def_theme]}
          >
            <Saturation height={185} color={color} onChange={handleChange}/>
            <Box h='5px'/>
            <Input
              onChange={handleChangeInput}
              border='none'
              h='40px'
              w='100%'
              rounded='7px'
              bg='#E2E2E2'
              color='#090909'
              fontSize='16px'
              fontWeight='500'
              variant='none'
              placeholder='#3DFF3F'
            />
            <Box h='5px'/>
            <Hue color={color} onChange={handleChange}/>
            <Flex mt='12px'>
              <Button
                onClick={cancelPaint}
                variant='none' bg="transparent"
                h='44px' w='100%' rounded='14px'
                color='rgba(10, 132, 255, 1)'
                fontSize='14px' fontWeight='600'
                lineHeight='22px'
                border='1px solid rgba(10, 132, 255, 1)'
              >CANCEL</Button>
              <Box w='10px'/>
              <DefButton
                onClick={saveThemeColor}
                bg='rgba(10, 132, 255, 1)' type="button">SET</DefButton>
            </Flex>
          </Box>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default ColorPalette;