import React from 'react';

const DesignSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.93734 23.2083H16.0623C21.1665 23.2083 23.2082 21.1666 23.2082 16.0625V9.93746C23.2082 4.83329 21.1665 2.79163 16.0623 2.79163H9.93734C4.83317 2.79163 2.7915 4.83329 2.7915 9.93746V16.0625C2.7915 21.1666 4.83317 23.2083 9.93734 23.2083Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.225 15.45C14.225 16.5321 13.7554 17.5121 13 18.1858C12.3467 18.7779 11.4891 19.125 10.55 19.125C8.51852 19.125 6.875 17.4815 6.875 15.45C6.875 13.7554 8.02857 12.3263 9.58023 11.9077C9.99877 12.9694 10.9073 13.7963 12.0302 14.1025C12.3364 14.1842 12.6631 14.2352 13 14.2352C13.3369 14.2352 13.6636 14.1944 13.9698 14.1025C14.1331 14.5108 14.225 14.9702 14.225 15.45Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.6751 10.55C16.6751 11.0298 16.5833 11.4892 16.4199 11.9077C16.0014 12.9694 15.0929 13.7963 13.97 14.1025C13.6637 14.1842 13.337 14.2352 13.0002 14.2352C12.6633 14.2352 12.3366 14.1944 12.0304 14.1025C10.9074 13.7963 9.99895 12.9796 9.5804 11.9077C9.41707 11.4892 9.3252 11.0298 9.3252 10.55C9.3252 8.51854 10.9687 6.875 13.0002 6.875C15.0316 6.875 16.6751 8.51854 16.6751 10.55Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M19.125 15.45C19.125 17.4814 17.4815 19.125 15.45 19.125C14.5109 19.125 13.6533 18.7677 13 18.1858C13.7554 17.5223 14.225 16.5423 14.225 15.45C14.225 14.9702 14.1331 14.5108 13.9698 14.0923C15.0927 13.786 16.0012 12.9693 16.4198 11.8975C17.9714 12.3262 19.125 13.7554 19.125 15.45Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default DesignSvg;