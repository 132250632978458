import {AppDispatch} from "../../../redux/store";
import PRIVATE_API, {PUBLIC_API} from "../../../api";
import {designSlice} from "./slice";
import {IDesign} from "../../../models/design";
import toast from "react-hot-toast";
import {toastCreator} from "../../../config/toast";
import {getProfile} from "../../Profile/edit-profile/ProfileReducer/actions";

export const addTheme = (design: IDesign, user_id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(designSlice.actions.setLoading())
    const {data} = await PUBLIC_API.post('api/design/', {...design, user_id})
    dispatch(designSlice.actions.addedDesign(null))
    toast.success("The page theme has been successfully updated")
    dispatch(getProfile())
  }catch (e: any) {
    toastCreator("error")
    dispatch(designSlice.actions.addedDesign(e.response?.data?.message || e.detail))
  }
}