import {RouteProps} from "react-router-dom";
import {Leads, Profile, Home, Design, Analytics} from "../pages";
import ResetLink from "../pages/ResetLink";
import MainPage from "../pages/LayoutPages/MainPage";
import AddUserBtn from "../admin/AddUserBtn";

export const privateRoutes: RouteProps[] = [
  {
    path: "/profile",
    element: <Profile/>,
  },
  {
    path: "/leads",
    element: <Leads/>,
  },
  {
    path: "/design",
    element: <Design/>,
  },
  {
    path: "/analytics",
    element: <Analytics/>,
  },
]

export const publicRoutes: RouteProps[] = [
  {
    path: "/user/:uid",
    element: <Home/>
  },
  {
    path: "/",
    element: <MainPage/>
  },
  {
    path: "/password/reset/confirm/:uid/:token",
    element: <ResetLink/>
  }
]

export const routesAdmin: RouteProps[] = [
  {
    path: '/admin',
    element: <AddUserBtn/>
  }
]
