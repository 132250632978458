import React, { FC, ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

//local
import {
  HEADER_HEIGHT,
  INTERFACE_PADDING_X,
  INTERFACE_WIDTH,
  NAVBAR_HEIGHT, SAVE_BTN_HEIGHT
} from "../../config/_variables";
import Navbar from "../../components/Navigations/Navbar";
import {useAppSelector} from "../../config/hooks/redux";
import {main_theme} from "../../config/colors";

type IProps = {
  children: ReactNode;
};

const InterfacePages: FC<IProps> = ({ children }) => {
  const { pathname } = useLocation();
  const { def_theme, def_font} = useAppSelector(state => state.DesignReducer)
  const atHome = pathname.includes('user') || pathname === "/"
  return (
    <Box
      maxW={INTERFACE_WIDTH}
      minHeight="100vh"
      px={atHome ? '0' : INTERFACE_PADDING_X}
      mx="auto"
      bg={main_theme[def_theme]}
      position="relative"
      pt={atHome ? '0' : HEADER_HEIGHT}
      pb={atHome ? SAVE_BTN_HEIGHT : NAVBAR_HEIGHT}
    >
      {children}
      {atHome ? null : <Navbar />}
    </Box>
  );
};

export default InterfacePages;
