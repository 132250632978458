import React from 'react';

const LocationSvg = () => {
  return (
    <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 18.261C6.81703 18.058 6.57292 17.7822 6.28641 17.4469C5.63684 16.6865 4.7717 15.6224 3.90765 14.4058C3.04253 13.1876 2.18527 11.8261 1.54599 10.4705C0.903356 9.10778 0.5 7.79038 0.5 6.64998C0.5 3.27348 3.38167 0.499976 7 0.499976C10.6183 0.499976 13.5 3.27348 13.5 6.64998C13.5 7.79038 13.0966 9.10778 12.454 10.4705C11.8147 11.8261 10.9575 13.1876 10.0923 14.4058C9.2283 15.6224 8.36316 16.6865 7.71359 17.4469C7.42708 17.7822 7.18297 18.058 7 18.261ZM4 6.64998C4 8.26098 5.36833 9.52498 7 9.52498C8.63167 9.52498 10 8.26098 10 6.64998C10 5.03898 8.63167 3.77498 7 3.77498C5.36833 3.77498 4 5.03898 4 6.64998Z" stroke="white"/>
    </svg>

  );
};

export default LocationSvg;