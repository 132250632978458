import React, {FC} from 'react';
import {Box, Image} from "@chakra-ui/react";
import Slider from "react-slick";
import {INTERFACE_WIDTH} from "../../../../config/_variables";
import {IGallery} from "../../../../models/company";

interface Props {
  galley: IGallery
}

const ImageSlides: FC<Props> = ({galley}) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    width: INTERFACE_WIDTH,
    fade: true,
    autoplay: true,
    adaptiveHeight: true,
    autoplaySpeed: 4500,
  };


  return (
    <Box mt='10px' className='company_slides'>
      <Slider {...settings}>
        {galley.images?.map((el, idx) => (
          <Box key={idx}>
            <Image
              objectFit="cover"
              h="100%"
              w="100%"
              src={el.image}
              alt={el.image}
              borderRadius={8}
              border="none"
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default ImageSlides;