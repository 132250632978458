import React, {useEffect, useState} from 'react';
import InterfaceHeader from "../../components/Navigations/InterfaceHeader";
import {Box, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react";
import StaticTabMenu from "./StaticTabMenu";
import CircleTotal from "./CircleTotal";
import ShowGetDate from "./ShowGetDate";
import {englishMonths, isToday} from "./helpersStatistics";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";
import {StatisticType} from "../../models/analytics";
import SmallRectangleQuantity from "./SmallRectangleQuantity";
import RectangleQuantity from "./RectangleQuantity";
import {getAnalytics} from "./reducer/actions";

const Analytics = () => {

  const dispatch = useAppDispatch();

  const [date, setDate] = useState<Date>(new Date());
  const [typeDate, setTypeDate] = useState<StatisticType>("day");

  const {
    analytics: {by_date, total},
    isLoading,
  } = useAppSelector(s => s.AnalyticsReducer);

  const handlePrevious = (type: StatisticType) => {
    // Создание копии текущей даты.
    const newDate = new Date(date);

    if (type === "day") {
      newDate.setDate(newDate.getDate() - 1);
    } else if (type === "month") {
      newDate.setMonth(newDate.getMonth() - 1);
    } else if (type === "year") {
      newDate.setFullYear(newDate.getFullYear() - 1);
    }

    // Обновление состояния date с новой датой.
    setDate(newDate);
  };

  const handleNext = (type: StatisticType) => {
    // Создание копии текущей даты.
    const newDate = new Date(date);

    // Получение текущей даты и времени.
    const currentDate = new Date();

    if (type === "day") {
      newDate.setDate(newDate.getDate() + 1);
    } else if (type === "month") {
      newDate.setMonth(newDate.getMonth() + 1);
    } else if (type === "year") {
      newDate.setFullYear(newDate.getFullYear() + 1);
    }

    // Проверка, что новая дата превышает текущую дату.
    if (newDate > currentDate) {
      // Если новая дата больше текущей, устанавливаем текущую дату в качестве новой даты.
      setDate(currentDate);
    } else {
      // Иначе обновляем состояние date с новой датой.
      setDate(newDate);
    }
  };

  useEffect(() => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    dispatch(getAnalytics(typeDate, `${year}-${month}-${day}`));
  }, [date, dispatch, typeDate]);

  const tabPanelList = [
    <>
      <ShowGetDate
        isToday={isToday(date)}
        text={`${isToday(date) ? "Today, " : ""}${
          englishMonths[date.getMonth()]
        } ${date.getDate()}`}
        onClickPrev={() => handlePrevious("day")}
        onClickNext={() => handleNext("day")}
      />
      <Box display="flex" justifyContent="center" py="10px">
        <CircleTotal text={by_date} width={181} isLoading={isLoading}/>
      </Box>
      <Text textAlign="center">People Saved You</Text>
      <Box display="flex" justifyContent="space-between" pt="10px">
        <SmallRectangleQuantity
          text="Day"
          quantity={by_date}
          isLoading={isLoading}
        />
        <SmallRectangleQuantity
          text="Total"
          quantity={total}
          isLoading={isLoading}
        />
      </Box>
    </>,
    <>
      <ShowGetDate
        isToday={isToday(date)}
        text={englishMonths[date.getMonth()]}
        onClickPrev={() => handlePrevious("month")}
        onClickNext={() => handleNext("month")}
      />
      <RectangleQuantity
        children={
          <CircleTotal text={by_date} width={147} isLoading={isLoading}/>
        }
        title={englishMonths[date.getMonth()]}
        text={date.getFullYear()}
      />
      <RectangleQuantity
        children={
          <CircleTotal
            text={total}
            width={147}
            bg="#181818"
            isLoading={isLoading}
          />
        }
        title="Total"
      />
    </>,
    <>
      <ShowGetDate
        isToday={isToday(date)}
        text={`${date.getFullYear()}`}
        onClickPrev={() => handlePrevious("year")}
        onClickNext={() => handleNext("year")}
      />
      <RectangleQuantity
        children={
          <CircleTotal text={by_date} width={147} isLoading={isLoading}/>
        }
        title={date.getFullYear()}
      />
      <RectangleQuantity
        children={
          <CircleTotal
            text={total}
            width={147}
            bg="#181818"
            isLoading={isLoading}
          />
        }
        title="Total"
      />
    </>,
    <>
      <RectangleQuantity
        children={
          <CircleTotal
            text={total}
            width={147}
            bg="#181818"
            isLoading={isLoading}
          />
        }
        title="Total"
      />
    </>,
  ];


  return (
    <div>
      <InterfaceHeader
        title={"Analytics"}
        pathToBack='/analytics'
      />


      <Box fontFamily="Poppins" mt='47px'>
        <Tabs position="relative" variant="unstyled">
          <StaticTabMenu setTypeDate={setTypeDate}/>
          <TabPanels color="white" mt="10px">
            {tabPanelList.map((el, idx) => (
              <TabPanel key={idx}>{el}</TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </div>
  );
};

export default Analytics;