import {AppDispatch} from "../../../redux/store";
import {changePasswordSlice} from "./slice";
import {PUBLIC_API} from "../../../api";
import toast from "react-hot-toast";

export const onChangePassword = (token: string, uid: string, new_password: string, redirect: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(changePasswordSlice.actions.setLoading())
    await PUBLIC_API.post('auth/users/reset_password_confirm/', {
      token,
      uid,
      new_password
    })
    dispatch(changePasswordSlice.actions.changedPassword(null))
    redirect(`/user/${uid}`);
    toast.success('your password has been successfully updated')
  } catch (e: any) {
    dispatch(changePasswordSlice.actions.changedPassword(e.message))
    toast.error('An error has occurred')
  }
}