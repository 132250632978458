import {AppDispatch} from "../../../../redux/store";
import {PUBLIC_API} from "../../../../api";
import {getLocalStorage} from "../../../../config/helpers";
import toast from "react-hot-toast";
import {forgotPasswordSlice} from "./slice";

export const sendToEmail = (email: string, onOpen: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(forgotPasswordSlice.actions.setLoading())
    const {data} = await PUBLIC_API.post('auth/users/reset_password/', {
      email,
    })
    dispatch(forgotPasswordSlice.actions.changedPassword(null))
    dispatch(forgotPasswordSlice.actions.onToggleChangePassword(false))
    toast.success('A message has been sent to your email address. Please check')
    onOpen()
  }catch (e: any) {
    dispatch(forgotPasswordSlice.actions.changedPassword(e.message))
    dispatch(forgotPasswordSlice.actions.onToggleChangePassword(true))
    toast.error('An error has occurred')
  }
}

export const onChangePassword = (uid: string, new_password: string) => async (dispatch: AppDispatch) => {
  try {
    const token = getLocalStorage('access')
    dispatch(forgotPasswordSlice.actions.setLoading())
    const {data} = await PUBLIC_API.post('auth/users/reset_password_confirm/', {
      token,
      uid,
      new_password
    })

    dispatch(forgotPasswordSlice.actions.changedPassword(null))
    dispatch(forgotPasswordSlice.actions.onToggleChangePassword(false))
    toast.success('your password has been successfully updated')
  }catch (e: any) {
    dispatch(forgotPasswordSlice.actions.changedPassword(e.message))
    dispatch(forgotPasswordSlice.actions.onToggleChangePassword(true))
    toast.error('An error has occurred')
  }
}