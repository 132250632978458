import React, {useEffect, useState} from 'react';
import {Box, Flex, SimpleGrid} from "@chakra-ui/react";
import ProfileInput from "../../../components/ui/inputs/ProfileInput";
import SaveChanges from "../../../components/ui/Buttons/SaveChanges";
import Location from "./location";
import AddButtons from "./AddButtons";
import AddPhotos from "./AddPhotos";
import AddVideo from "./Gallery/AddVideo";
import TitleText from "../../../components/ui/text/TitleText";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {addCompany} from "./CompanyReducer/action";
import {ICompany} from "../../../models/company";
import AddFileInput from "../../../components/ui/inputs/AddFileInput";
import {useFileDecoder} from "../../../config/hooks/file-decoder";
import EditButton from "./AddButtons/EditButton";
import Gallery from "./Gallery";
import {location_button} from "../../../components/custom-buttons/data";
import RemoveCompany from "./removeCompany";

const EditCompany = () => {
  const dispatch = useAppDispatch()
  const {company, buttons} = useAppSelector(s => s.CompanyReducer)
  const {user: {id: user}} = useAppSelector(s => s.ProfileReducer)
  const [value, setValue] = useState<ICompany>(company)

  const [handleChangeFile, url, files] = useFileDecoder()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmitProfile = (e: React.FormEvent<HTMLFormElement | HTMLTextAreaElement>) => {
    e.preventDefault()
    dispatch(addCompany({...value, user}, files[0]))
  }
  useEffect(() => {
    setValue(company)
  }, [company])
  return (
    <Box>
      <Box mt='60px' mb='15px'>
        <TitleText
          fontWeight='500'
          fontSize='16px'
          lineHeight='22px'
        >About Company</TitleText>
      </Box>
      <form onSubmit={onSubmitProfile}>
        <AddFileInput
          handleChange={handleChangeFile}
          value={files[0]?.name || company.logo}
          placeholder={"Company logo"}
        />
        <ProfileInput
          name='name'
          handleChange={handleChange}
          value={value.name}
          placeholder={"Company name"}
          required={true}
        />
        <ProfileInput
          as='textarea'
          handleChange={handleChange}
          value={value.description}
          name='description'
          placeholder={"Description"}
          required={false}
        />
        <Flex justifyContent='end' alignItems='center'>
          <SaveChanges disabled={false}/>
        </Flex>
      </form>

      <Box mt='13px'>
        <TitleText
          fontWeight='500'
          fontSize='16px'
          lineHeight='22px'
        >Company Details</TitleText>
        <SimpleGrid columns={3} spacing={'10px 8px'} mt='15px'>
          {buttons.map(btn => (
            <EditButton btn={btn} key={btn.id}/>
          ))}
          {!!company.locations && <EditButton btn={location_button} isLocation={true}/>}
        </SimpleGrid>
        <SimpleGrid columns={2} spacingX={'10px'}>
          <Location/>
          <AddButtons/>
        </SimpleGrid>
      </Box>
      <Gallery/>
      <RemoveCompany/>
    </Box>
  );
};

export default EditCompany;