import { Box, Text } from "@chakra-ui/react";
import React, { FC } from "react";
import {NotLeadsSvg} from "../../../assests/svg/NotLeadsSvg";

const NoLeads: FC = () => {
  return (
    <Box
      w="100%"
      minH="70vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box fontFamily="Poppins">
        <NotLeadsSvg />
        <Text color="#ffffff" fontSize="15px" textAlign="center">
          No one else here
        </Text>
      </Box>
    </Box>
  );
};

export default NoLeads;
