import React from 'react';
import Placement from "../../components/ui/placement";
import ResetPassword from "./ResetPassword";
import {useParams} from "react-router-dom";

const ResetLink = () => {

  const {uid, token} = useParams()

  if (!uid || !token) {
    return null;
  }
  return (
    <Placement
      isOpen={true}
      onClose={() => {
      }}
      children={<ResetPassword uid={uid} token={token}/>}
      title="New password"
    />
  );
};

export default ResetLink;