import React, {useState} from 'react';
import Placement from "../../ui/placement";
import {custom_buttons} from "../data";
import {Box, Button, useDisclosure} from "@chakra-ui/react";
import {useAppSelector} from "../../../config/hooks/redux";
import {IContactButton, IParentButtons} from "../../../models/custom";
import {buttons_theme} from "../../../config/colors";
import {MdOutlineKeyboardArrowRight} from "react-icons/md";
import ProfileInput from "../../ui/inputs/ProfileInput";
import DefButton from "../../ui/Buttons/DefButton";
import AddFileInput from "../../ui/inputs/AddFileInput";
import {useFileDecoder} from "../../../config/hooks/file-decoder";
import CustomButton from "./CustomButton";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (btn: IContactButton) => void;
}

const CustomModal = ({isOpen, onClose, onSubmit}: Props) => {
  return (
    <Placement isOpen={isOpen} onClose={onClose} title={"Company Details"}>
      <Box>
        {custom_buttons.map((el, idx) =>
          <CustomModalBtn
            key={idx}
            onSubmit={onSubmit}
            el={el}
          />)}
      </Box>
    </Placement>
  );
};


interface Props2 {
  el: IParentButtons,
  onSubmit: (btn: IContactButton) => void
}

function CustomModalBtn({el, onSubmit}: Props2) {
  const {def_theme} = useAppSelector(state => state.DesignReducer)

  const {onClose, isOpen, onOpen} = useDisclosure()

  return (
    <>
      <Button
        onClick={onOpen}
        rounded='14px'
        variant='none'
        w='100%' h='42px' mb='2.5'
        bg={buttons_theme[def_theme]}
        fontWeight='500' fontSize='16px'
        color='rgba(255, 255, 255, 1)'
        fontFamily='Poppins'
        justifyContent='space-between'
      >{el.name}
        <Box>
          <MdOutlineKeyboardArrowRight/>
        </Box>
      </Button>

      <Placement isOpen={isOpen} onClose={onClose} title={el.name}>
        <Box>
          {el.value?.map((btn, idx) =>
            <CustomButton
              key={idx}
              btn={btn}
              onSubmit={onSubmit}
            />)}
        </Box>
      </Placement>
    </>
  )
}

export default CustomModal;