import React, {useState} from 'react';
import {Box, Button, Text, useDisclosure} from "@chakra-ui/react";
import Placement from "../../../../components/ui/placement";
import ProfileInput from "../../../../components/ui/inputs/ProfileInput";
import DefButton from "../../../../components/ui/Buttons/DefButton";
import {useAppDispatch, useAppSelector} from "../../../../config/hooks/redux";
import {sendLead} from "../../../Leads/reducer/action";

const ExchangeContact = () => {
  const dispatch = useAppDispatch()
  const {onClose, isOpen, onOpen} = useDisclosure()
  const {isLoading} = useAppSelector(s => s.PostLeadReducer)
  const {user: {id: owner}} = useAppSelector(s => s.ProfileReducer)
  const [value, setValue] = useState({
    name: "",
    phone: "",
    email: "",
    message: ""
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(sendLead({...value, owner}, onClose))
  }
  return (
    <>
      <Button
        onClick={onOpen}
        variant='none'
        bg='rgba(37, 37, 40, 1)'
        border='1px solid rgba(255, 255, 255, 0.5)'
        h='44px' w='100%'
        rounded='14px'
        color='rgba(255, 255, 255, 1)'
        fontSize='14px'
        fontWeight='600'
        lineHeight='22px'
        textTransform='uppercase'
      >Exchange contact</Button>

      <Placement isOpen={isOpen} onClose={onClose} title={"Exchange Contact"}>
        <form onSubmit={onSubmit}>

          <ProfileInput
            handleChange={handleChange}
            required={true}
            name={"name"}
            placeholder={"Name"}/>

          <ProfileInput
            handleChange={handleChange}
            required={true}
            name={"phone"}
            type={"phone"}
            placeholder={"Number"}/>

          <ProfileInput
            handleChange={handleChange}
            required={false}
            name={"email"}
            type={"email"}
            placeholder={"Email*"}/>

          <ProfileInput
            handleChange={handleChange}
            required={false}
            name={"message"}
            placeholder={"Comment...*"}
            as='textarea'/>

          <Box mt='37px'>
            <DefButton isLoading={isLoading}>Save</DefButton>
          </Box>
        </form>
      </Placement>
    </>
  );
};

export default ExchangeContact;