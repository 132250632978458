import React, {useState} from 'react';
import FileInput from "../../../../components/ui/inputs/FileInput";
import {Avatar, Box, Button, Stack} from "@chakra-ui/react";
import {BsPlusLg} from "react-icons/bs";
import {useFileReader} from "../../../../config/hooks/file-decoder";
import {useAppDispatch, useAppSelector} from "../../../../config/hooks/redux";
import {addProfileAvatar} from "../ProfileReducer/actions";
import {BiSolidTrashAlt} from "react-icons/bi";
import PersonGif from "../../../../assests/gif/Person.gif"

const AvatarLoad = () => {
  const [handleChange, file] = useFileReader(addAvatar)
  const {user: {photo}} = useAppSelector(state => state.ProfileReducer)
  const dispatch = useAppDispatch()
  function addAvatar(file: File){
    dispatch(addProfileAvatar(file))
  }
  function removeAvatar(){
    dispatch(addProfileAvatar(""))
  }
  //
  return (
    <div>
      <Box mx='auto' p='15px' position='relative'>
        {photo && <Avatar
          objectFit='cover'
          w='163px'
          h='163px'
          src={photo}
        />}

        {!photo && <Avatar
          objectFit='cover'
          w='163px'
          h='163px'
          src={PersonGif}
        />}
        <Box position='absolute' bottom='16px' right='16px' w='41px' overflow='hidden'>
          <Stack as='label' w='100%' h='41px' rounded='50%'  zIndex='2' position='relative' display='inline-block'>
            <FileInput
              handleChange={handleChange}
              accept={['.png', '.jpg', '.HEIC', '.jpeg', '.svg', '.gif']}
              multi={false}/>
            <Button
              as='span'
              position='relative'
              display='inline-block'
              cursor='pointer' w='100%' h='100%'
              outline='none' variant='none'
              rounded='50%' bg={'#0080DDFF'} mx='auto' p='0'
              _focus={{border: 'none'}}
              _active={{bg: 'white'}}
            ><Button
              variant='none' as='div' p='0'
              color='#FFFFFF' fontSize='1.4rem'
              _active={{color: '#0080DDFF'}}
              w='100%' h='100%' justifyContent='center'
              alignItems='center' rounded='50%'>
              <BsPlusLg/></Button>
            </Button>
          </Stack>
        </Box>
        <Box position='absolute' bottom='16px' right='-35px'>
          {!!photo && <Button
            onClick={removeAvatar}
            // isDisabled={load}
            variant='none' fontSize='1.6rem' p='0'
            w='37px' h='37px' rounded='4px'
            bg='#FFFFFF1A' color='#D92C41' _active={{bg: '#FFFFFF4C'}}
          ><BiSolidTrashAlt/>
          </Button>}
        </Box>
      </Box>
    </div>
  );
};

export default AvatarLoad;