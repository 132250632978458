import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ILeadsState {
  isLoading: boolean;
  isError: string | null
}

const initialState: ILeadsState = {
  isError: null,
  isLoading: false,
}

export const postLeadSlice = createSlice({
  name: "post/leads",
  initialState,
  reducers: {
    setLoading(state){
      state.isLoading = true
    },
    onSendLead(state, action: PayloadAction<string | null>){
      state.isLoading = false;
      state.isError = action.payload
    },
  }
})

export const PostLeadReducer = postLeadSlice.reducer