import React from 'react';
import {useAppSelector} from "../../../../config/hooks/redux";
import {Box, Text} from "@chakra-ui/react";
import Slider from "react-slick";
import YoutubeVideo from "./YoutubeVideo";
import TitleText from "../../../../components/ui/text/TitleText";

const Youtube = () => {
  const {company: {videos}, isLoading} = useAppSelector(s => s.CompanyReducer);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  if (isLoading) {
    return null;
  }

  return (
    <>
      {videos.length > 0 && (
        <Box
          mt="30px"
          fontFamily="Poppins"
          color="#FFFFFF"
          className="video__youtube company_slides"
        >
          <TitleText>Video</TitleText>
          <Box bg="#000" borderRadius={20} mt="10px">
            <Slider {...settings}>
              {videos.map((el) => (
                <YoutubeVideo key={el.id} video={el}/>
              ))}
            </Slider>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Youtube;