import React, {ChangeEvent, FC, FormEvent, useEffect, useState} from 'react';
import ProfileInput from "../../components/ui/inputs/ProfileInput";
import DefButton from "../../components/ui/Buttons/DefButton";
import ErrorText from "../../components/ui/text/ErrorText";
import {Box} from "@chakra-ui/react";
import {onChangePassword} from "./reducer/action";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";
import {useNavigate} from "react-router-dom";

type Props = {
  uid: string;
  token: string;
};

interface IPassword {
  password: string;
  confirm_password: string;
}

const ResetPassword: FC<Props> = ({ uid, token }) => {
  const [validation, setValid] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {isLoading} = useAppSelector(s => s.ChangePasswordReducer)
  //инициализация состояние пароля и подтверждение пароля
  const [resetPsw, setResetPsw] = useState<IPassword>({
    password: "",
    confirm_password: "",
  });

  //обработчик изменений полей ввода пароля
  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setResetPsw({ ...resetPsw, [event.target.name]: event.target.value });
  };

  //обработчик отправки формы
  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (resetPsw.password === resetPsw.confirm_password) {
      dispatch(onChangePassword(token, uid, resetPsw.password, navigate))
    } else {
      setValid(true);
    }
  };

  //следящий за изменениями паролей и сравнивающий их для установки текста ошибки

  useEffect(() => {
    if (!!resetPsw.confirm_password){
      setValid(resetPsw.password !== resetPsw.confirm_password)
    }
  }, [resetPsw.confirm_password, resetPsw.password])

  return (
    <form onSubmit={onSubmit} style={{ paddingBottom: "1.6rem" }}>
      <ProfileInput
        placeholder="New Password"
        name="password"
        value={resetPsw.password}
        handleChange={handleChange}
        required={true}
      />
      <ProfileInput
        placeholder="Confirm Password"
        name="confirm_password"
        value={resetPsw.confirm_password}
        handleChange={handleChange}
        required={true}
      />
      {validation && <ErrorText>passwords must match</ErrorText>}
     <Box  mt='37px'>
       <DefButton isLoading={isLoading} disabled={validation}>
         Save
       </DefButton>
     </Box>
    </form>
  );
};

export default ResetPassword;