import React from 'react';

const TelegramSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9937 2.16667C10.8511 2.16667 8.75659 2.80204 6.97506 3.99242C5.19353 5.1828 3.80499 6.87473 2.98504 8.85427C2.16509 10.8338 1.95056 13.012 2.36856 15.1135C2.78657 17.2149 3.81835 19.1453 5.33342 20.6603C6.84848 22.1754 8.7788 23.2072 10.8803 23.6252C12.9817 24.0432 15.1599 23.8287 17.1395 23.0087C19.119 22.1888 20.8109 20.8002 22.0013 19.0187C23.1917 17.2372 23.8271 15.1426 23.8271 13C23.8272 11.5773 23.5471 10.1685 23.0027 8.85413C22.4583 7.53971 21.6603 6.34541 20.6543 5.33942C19.6483 4.33343 18.454 3.53545 17.1396 2.99107C15.8252 2.44669 14.4164 2.16655 12.9937 2.16667ZM16.4382 18.5818C16.3977 18.683 16.336 18.7742 16.2573 18.8495C16.1785 18.9249 16.0847 18.9825 15.9819 19.0185C15.8791 19.0546 15.7697 19.0683 15.6612 19.0586C15.5527 19.049 15.4475 19.0163 15.3527 18.9626L12.4118 16.6775L10.5243 18.4188C10.4805 18.4512 10.4293 18.472 10.3753 18.4794C10.3214 18.4868 10.2664 18.4804 10.2156 18.461L10.5774 15.2235L10.589 15.2327L10.5964 15.1688C10.5964 15.1688 15.8884 10.3507 16.104 10.1454C16.3223 9.94063 16.2503 9.89621 16.2503 9.89621C16.2627 9.64647 15.8586 9.89621 15.8586 9.89621L8.84674 14.4073L5.92662 13.4128C5.92662 13.4128 5.47812 13.2519 5.43587 12.8982C5.39146 12.5472 5.94124 12.3565 5.94124 12.3565L17.5513 7.7442C17.5513 7.7442 18.5057 7.31897 18.5057 8.02317L16.4382 18.5818Z" fill="white"/>
    </svg>

  );
};

export default TelegramSvg;