import {AppDispatch} from "../../../../redux/store";
import PRIVATE_API, {PUBLIC_API} from "../../../../api";
import toast from "react-hot-toast";
import {toastCreator} from "../../../../config/toast";
import {postProfileSlice} from "../../edit-profile/ProfileReducer/postProfile.slice";
import {companySlice, empty_company} from "./slice";
import {ICompany, ILocation, RootCompany} from "../../../../models/company";

export const addCompany = (company: ICompany, logo: File) => async (dispatch: AppDispatch) => {
  try {
    let formData = new FormData()
    formData.append('name', company.name)
    formData.append('description', company.description)
    !!logo && formData.append('logo', logo)
    const config = {
      headers: {'content-type': 'multipart/form-data'}
    }
    dispatch(postProfileSlice.actions.addProfile());
    const value: any = {...company}
    delete value.id
    const {data} = company.id ?
      await PRIVATE_API.patch(`api/companies/${company.id}/`, formData, config)
      : await PRIVATE_API.post('api/companies/', {user: company.user, name: company.name}).then(({data: comp}) => {
        return PRIVATE_API.patch(`api/companies/${comp.id}/`, formData, config)
      })

    dispatch(postProfileSlice.actions.addProfileSuccess());
    toast.success("Company successfully updated");
    dispatch(getCompany())
  } catch (e: any) {
    toastCreator("error", e.response?.data?.message || e.detail);
    dispatch(
      postProfileSlice.actions.addProfileError(
        e.response?.data?.message || e.detail
      )
    );
  }
}

export const addLocation = (location: ILocation, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postProfileSlice.actions.addProfile());
    const value: ILocation = {...location}
    delete value.id
    const {data} = await PRIVATE_API.post('api/location/', value)
    dispatch(postProfileSlice.actions.addProfileSuccess());
    toast.success("Address successfully updated");
    dispatch(getCompany())
    onClose()
  } catch (e: any) {
    toastCreator("error", e.response?.data?.message || e.detail);
    dispatch(
      postProfileSlice.actions.addProfileError(
        e.response?.data?.message || e.detail
      )
    );
  }
}

export const getCompany = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(companySlice.actions.setLoading());
    const {data} = await PRIVATE_API.get<RootCompany>('api/companies/')
    const [company] = data
    dispatch(companySlice.actions.getCompanySuccess(company || empty_company));
    dispatch(companySlice.actions.getCompanies(data));
  } catch (e: any) {
    dispatch(
      companySlice.actions.getCompanyError(
        e.response?.data?.message || e.detail
      )
    );
  }
}

export const getCompanyByUid = (uid: any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(companySlice.actions.setLoading());
    const {data} = await PUBLIC_API.get<RootCompany>(`api/companies/?search=${uid}`)
    const [company] = data
    dispatch(companySlice.actions.getCompanySuccess(company));
    dispatch(companySlice.actions.getCompanies(data));
  } catch (e: any) {
    dispatch(
      companySlice.actions.getCompanyError(
        e.response?.data?.message || e.detail
      )
    );
  }
}

export const deleteCompany = (id: number, onClose: () => any) => async (dispatch: AppDispatch) => {
  try {
    dispatch(postProfileSlice.actions.addProfile());
    await PRIVATE_API.delete(`api/companies/${id}/`)
    dispatch(postProfileSlice.actions.addProfileSuccess());
    toast.success("Company deleted");
    dispatch(getCompany())
    onClose()
  } catch (e: any) {
    toast.error(e.response?.data?.detail);
    dispatch(
      postProfileSlice.actions.addProfileError(
        e.response?.data?.message || e.detail
      )
    );
  }
}



