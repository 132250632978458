import React from 'react';

const YoutubeSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.4167 4.33333H7.58341C4.33341 4.33333 2.16675 6.49999 2.16675 9.75V16.25C2.16675 19.5 4.33341 21.6667 7.58341 21.6667H18.4167C21.6667 21.6667 23.8334 19.5 23.8334 16.25V9.75C23.8334 6.49999 21.6667 4.33333 18.4167 4.33333ZM15.0476 14.1158L12.3717 15.7192C11.2884 16.3692 10.4001 15.8708 10.4001 14.6033V11.3858C10.4001 10.1183 11.2884 9.62001 12.3717 10.27L15.0476 11.8733C16.0768 12.5017 16.0768 13.4983 15.0476 14.1158Z" fill="white"/>
    </svg>

  );
};

export default YoutubeSvg;