import React from 'react';
import {Box, Flex, Image} from "@chakra-ui/react";
// @ts-ignore
import Logo from "../../assests/img/Logo.png"

const MainPage = () => {
  return (
    <Flex justifyContent='center' alignItems='center'>
      <Image width='80%' mt='100px' src={Logo} alt={"Logo"}/>
    </Flex>
  );
};

export default MainPage;