import React from 'react';
import {Button, Flex, Text} from "@chakra-ui/react";
import {AiOutlinePlus} from "react-icons/ai";
import {useAppSelector} from "../../../config/hooks/redux";
import {add_btn_theme} from "../../../config/colors";

interface Props {
  onClick: () => void;
  name: string;
  icon?: JSX.Element | null
}

const PlusButton = ({onClick, name, icon = null}: Props) => {
  const {def_theme} = useAppSelector(state => state.DesignReducer)
  const {company: {id}} = useAppSelector(state => state.CompanyReducer)
  return (
    <Button
      variant='none' onClick={onClick}
      isDisabled={!id}
      flexDirection='column' alignItems='center' mt='10px'
      w='100%' h='58px' rounded='8px' _active={{opacity: '.7'}}
      bg={add_btn_theme[def_theme]} py={2}

    >
      {!icon && <Flex
        justifyContent='center'
        alignItems='center'
        color='white'
        w='24px' h='24px'
        bg='rgba(0, 128, 221, 1)'
        rounded='50%'
        fontSize='.85rem'
      ><AiOutlinePlus/></Flex>}
      {!!icon && icon}
      <Text
        fontFamily='Poppins'
        fontWeight='400'
        fontSize='11px'
        lineHeight='12px'
        letterSpacing='0.07px'
        color='rgba(255, 255, 255, 1)'
        mt='6px'
      >{name}</Text>

    </Button>
  );
};

export default PlusButton;