import React, {useEffect} from 'react';
import InterfaceHeader from "../../components/Navigations/InterfaceHeader";
import {getLeads} from "./reducer/action";
import {useAppDispatch, useAppSelector} from "../../config/hooks/redux";
import {Box, SimpleGrid} from "@chakra-ui/react";
import LeadCard from "./LeadCard";
import NoLeads from "./NoLeads";
import Loader from "../../components/Loader";

const Leads = () => {
  const dispatch = useAppDispatch()
  const {leads, isLoading} = useAppSelector(s => s.LeadsReducer)
  useEffect(() => {
    dispatch(getLeads())
  }, [])

  return (
    <Box>
      {isLoading && <Loader/>}
      <InterfaceHeader
        title={"Leads"}
        pathToBack='/leads'
      />

      <Box mt='27px' px='10px'>
        {leads.length > 0 ? (
          <SimpleGrid columns={2} spacing='15px 8px'>
            {leads.map((lead) => <LeadCard key={lead.id} lead={lead}/>)}
          </SimpleGrid>
        ) : (
          <NoLeads/>
        )}
      </Box>
    </Box>
  );
};

export default Leads;