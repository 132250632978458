import {AppDispatch} from "../../../redux/store";
import {IContactButton} from "../../../models/custom";
import PRIVATE_API from "../../../api";
import {customSlice} from "./slice";
import {toastCreator} from "../../../config/toast";
import toast from "react-hot-toast";
import {getCompany} from "../../../pages/Profile/edit-company/CompanyReducer/action";
import {getProfile} from "../../../pages/Profile/edit-profile/ProfileReducer/actions";


export const addCustom = (btn: IContactButton, user: number, company: number, onClose: () => void) => async (dispatch: AppDispatch) => {
  try {
    const data: any = {
      button_type: btn.type,
      label: btn.name,
      value: btn.href + btn.value,
      company,
      user
    }
    !company && delete data.company
    !user && delete data.user
    dispatch(customSlice.actions.setLoading())
    await PRIVATE_API.post('api/custom-buttons/', data)
    toastCreator("success", "Button added successfully");
    dispatch(customSlice.actions.onSend(null))
    dispatch(getProfile())
    dispatch(getCompany())
    onClose()
  } catch (e: any) {
    toastCreator("error", e.response?.data?.detail || "Error");
    dispatch(customSlice.actions.onSend(e.response?.data?.detail))
  }
}

export const removeButton = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(customSlice.actions.setLoading())
    await PRIVATE_API.delete(`api/custom-buttons/${id}/`)
    toast.success('button removed')
    dispatch(getProfile())
    dispatch(getCompany())
    dispatch(customSlice.actions.onSend(null))
  }catch (e: any) {
    toastCreator('error', e.response?.data?.message)
    console.log('Error: ',e.response?.data?.message)
  }
}