import React from "react";
import {Box, Button, Divider, Flex, Modal, ModalContent, ModalOverlay, Text, useDisclosure} from "@chakra-ui/react";
import { AiOutlinePlus } from "react-icons/ai";
import { FaTrash } from "react-icons/fa";
import {useAppDispatch, useAppSelector} from "../../../config/hooks/redux";
import {ILead} from "../../../models/leads";
import {createVcfFile, getFormatDate} from "../../../config/helpers";
import {deleteLead} from "../reducer/action";
import {FiMinus} from "react-icons/fi";
import {add_btn_theme, box_theme, placement_theme} from "../../../config/colors";

//local


interface Props {
  lead: ILead;
}

const LeadCard = ({ lead }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {isLoading} = useAppSelector(state => state.LeadsReducer)
  const {def_theme, system_color} = useAppSelector(state => state.DesignReducer)
  const dispatch = useAppDispatch();

  const { name, email, message, phone, id} = lead;

  const onDelete = () => {
    dispatch(deleteLead(id)).then(() => onClose())
  }

  return (
    <Flex
      flexDirection="column"
      alignItems="start"
      rounded="11px"
      mt="10px"
      p="15px"
      // bg="#FFFFFF1A"
      bg={box_theme[def_theme]}
      w="100%"
      fontFamily="Poppins"
    >
      <Flex w="full" justifyContent="space-between" alignItems="start">
        <Flex
          w={{ sm: "90%", base: "70%" }}
          flexDirection="column"
          alignItems="start"
        >
          <Text
            as="h2"
            color={system_color}
            fontWeight="600"
            fontSize="18px"
            lineHeight="30px"
          >
            {name}
          </Text>
          <Text
            color="#A3A3A3"
            fontWeight="300"
            fontSize="14px"
            lineHeight="28px"
            maxW="140%"
          >
            {email}
          </Text>
          <Text
            color="#838383"
            fontWeight="300"
            fontSize="14px"
            lineHeight="21px"
            maxW="100%"
          >
            {phone}
          </Text>
        </Flex>
      </Flex>
      <Text
        color="#838383"
        fontWeight="300"
        mt={5}
        fontSize="12px"
        lineHeight="18px"
      >
        {message}
      </Text>
      <Text
        color="#838383"
        fontWeight="300"
        mt={3}
        fontSize="12px"
        lineHeight="18px"
        textAlign="end"
        w="full"
      >
        {/*{getFormatDate(created_date)}*/}
      </Text>

      <Flex>
        <Button
          rounded='50%'
          w="28px"
          h="38px"
          bg="#52525247"
          color={system_color}
          p="6px 7px"
          fontSize="2rem"
          mr={2}
          onClick={() => createVcfFile(name, phone, email)}
        >
          <AiOutlinePlus />
        </Button>
        <Button
          rounded='50%'
          onClick={onOpen}
          w="28px"
          h="38px"
          bg="#52525247"
          color="#D92C41"
          p="9px 10px"
          fontSize="1.8rem"
        >
          <FiMinus />
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size={{sm: 'md', base: 'sm'}} isCentered={true}>
       <ModalOverlay/>
        <ModalContent px='0' pb='0' pt='50px' bg='rgba(49, 51, 53, 0.7)' rounded='20px'>
          <Flex flexDirection="column" alignItems="center">
            <Text
              fontFamily='Poppins'
              color="#FFFFFF"
              fontWeight="400"
              fontSize="15px"
              lineHeight="22.5px"
              textAlign="center"
              w="70%"
            >
              Are you sure you want to be removed from the list?
            </Text>
            <Flex mt="20px" w="100%" h='67.5px' borderTop='0.5px solid rgba(159, 158, 159, 0.5)'>
              <Button
                onClick={onClose}
                variant='none'
                bg='transparent'
                border='none'
                w='50%' h='100%'
                fontFamily='Poppins'
                color="rgba(255, 255, 255, 1)"
                fontWeight="500"
                fontSize="20px"
                lineHeight="22px"
              >Cancel</Button>
              <Divider orientation='vertical' w='0.5px' h='100%' bg='rgba(159, 158, 159, 0.5)' opacity='.2'/>
              <Button
                onClick={onDelete}
                isLoading={isLoading}
                variant='none'
                bg='transparent'
                border='none'
                w='50%' h='100%'
                fontFamily='Poppins'
                color="rgba(90, 145, 247, 1)"
                fontWeight="500"
                fontSize="20px"
                lineHeight="22px"
              >Yes</Button>
            </Flex>
          </Flex>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default LeadCard;
