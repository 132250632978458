import React from 'react';

const AnalyticsSvg = () => {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.1151 20.4759V7.6926C21.1151 6.0676 20.4217 5.4176 18.6992 5.4176H17.5726C15.8501 5.4176 15.1567 6.0676 15.1567 7.6926V20.4759" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.94824 20.476V13.1094C5.94824 11.4844 6.64158 10.8344 8.36408 10.8344H9.49074C11.2132 10.8344 11.9066 11.4844 11.9066 13.1094V20.476" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.4165 20.5844H24.0832" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default AnalyticsSvg;