import React from 'react';

const ProfileTickSvg = () => {
  return (
    <svg
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5013 3.9165C18.3705 3.9165 14.1992 8.08775 14.1992 13.2186C14.1992 18.2515 18.1355 22.3248 23.2663 22.5011C23.423 22.4815 23.5796 22.4815 23.6971 22.5011C23.7363 22.5011 23.7559 22.5011 23.7951 22.5011C23.8146 22.5011 23.8146 22.5011 23.8342 22.5011C28.8476 22.3248 32.7838 18.2515 32.8034 13.2186C32.8034 8.08775 28.6321 3.9165 23.5013 3.9165Z"
        fill="#343A41"
      />
      <path
        d="M33.4498 27.7294C27.9861 24.0869 19.0757 24.0869 13.5728 27.7294C11.0857 29.3744 9.71484 31.6265 9.71484 34.0353C9.71484 36.444 11.0857 38.6765 13.5532 40.3215C16.2948 42.1624 19.8982 43.0828 23.5015 43.0828C27.1048 43.0828 30.7082 42.1624 33.4498 40.3215C35.9173 38.6569 37.2882 36.4244 37.2882 33.9961C37.2686 31.6069 35.9173 29.3549 33.4498 27.7294ZM28.0644 32.4294L23.1294 37.3644C22.8944 37.5994 22.5811 37.7169 22.2678 37.7169C21.9544 37.7169 21.6411 37.5799 21.4061 37.3644L18.9386 34.8969C18.4686 34.4269 18.4686 33.6436 18.9386 33.1736C19.4086 32.7036 20.1919 32.7036 20.6619 33.1736L22.2678 34.7794L26.3411 30.7061C26.8111 30.2361 27.5944 30.2361 28.0644 30.7061C28.554 31.1761 28.554 31.9594 28.0644 32.4294Z"
        fill="#343A41"
      />
    </svg>
  );
};

export default ProfileTickSvg;