import React from 'react';
import {Button, Divider, Menu, MenuButton, MenuDivider, MenuList} from "@chakra-ui/react";
import {FiMoreVertical} from "react-icons/fi";
import MyQrCode from "./MyQrCode";
import Login from "../../../../components/forms/login";
import ForgotPassword from "../../../../components/forms/forgot-password";
import ForgotSvg from "../../../../assests/svg/ForgotSvg";

const MenuBtn = () => {
  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button} variant='none' p='0' mr={2}
        justifyContent='center' alignItems='center'
        color='rgba(255, 255, 255, 1)'
        fontSize='2rem' cursor='pointer'
        _active={{opacity: '.7'}}
      >
        <FiMoreVertical/>
      </MenuButton>
      <MenuList
        maxW='210px'
        bg='rgba(49, 51, 53, 0.7)'
        p='0' border='none'
        rounded='13px' overflow='hidden'
      >
        <Login/>
        <Divider color='#FFFFFF33' />
        <MyQrCode/>
        <Divider color='#FFFFFF33' />
        <ForgotPassword nameBtn={"Forgot password"} icon={<ForgotSvg/>}/>
      </MenuList>
    </Menu>
  );
};

export default MenuBtn;