import {Box, MenuItem} from "@chakra-ui/react";
import React from "react";

interface Props {
  name: string;
  icon: any;
  onClick: () => void
}

function MenuItemUi({onClick, icon, name}: Props) {
  return (
    <MenuItem
      onClick={onClick}
      h='44px'
      fontFamily='Poppins'
      fontWeight='400'
      fontSize='17px'
      lineHeight='22px'
      color='rgba(255, 255, 255, 1)'
      bg='rgba(0, 0, 0, 0.2)'
      w='100%' alignItems='center'
      justifyContent='space-between'
    >
      {name}
      <Box>{icon}</Box>
    </MenuItem>
  )
}

export default MenuItemUi