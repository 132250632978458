import React, {useEffect, useState} from 'react';
import {useAppSelector} from "../../../../../config/hooks/redux";
import QRCode from "qrcode";
import {Link, Modal, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure} from "@chakra-ui/react";
import MenuItemUi from "../MenuItemUi";
import QrCodeImage from "./QrCodeImage";
import QRCodeSvg from "../../../../../assests/svg/QRCodeSvg";

const MyQrCode = () => {

  const {isOpen, onOpen, onClose} = useDisclosure();
  const [qrCode, setQrCode] = useState<string>("");
  const [qrText, setQrText] = useState<string>("");

  const { user } = useAppSelector((state) => state.ProfileReducer);
  const generateQrCode = async (text: string) => {
    try {
      const url = await QRCode.toDataURL(text, {
        width: 700,
      });
      setQrCode(url);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    qrText.length > 0 && generateQrCode(qrText);
  }, [qrText]);

  useEffect(() => {
    setQrText(`${window.location.origin}/user/${user.uid}`);
  }, [user.uid]);
  return (
    <>
      <MenuItemUi name={"QR code"} icon={<QRCodeSvg/>} onClick={onOpen}/>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
        <ModalOverlay
          bg={"rgba(73, 73, 73, 0.7)"}
          backdropFilter='blur(5.384px)'
        />
        <ModalContent
          rounded='31px'
          display="flex"
          justifyContent="center"
          alignItems="center"
          w="350px"
          h="444px"
        >
          <ModalCloseButton/>
          <QrCodeImage value={qrText}/>
          <br/>
          <Link
            color="blue"
            fontFamily="Poppins"
            fontWeight="300"
            fontSize="15px"
            href={qrCode}
            download={`${qrText}.png`}
          >
            Download to Gallery
          </Link>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MyQrCode;