import React from "react";
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent, DrawerHeader,
  DrawerOverlay, Progress,
} from "@chakra-ui/react";
import {INTERFACE_WIDTH} from "../../../config/_variables";
import TitleComponents from "../text/TitleComponent";
import {placement_theme} from "../../../config/colors";
import {useAppSelector} from "../../../config/hooks/redux";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
  title: string;
}

function Placement({
                     isOpen,
                     onClose,
                     children,
                     title,
                   }: Props) {

  const {def_theme, system_color} = useAppSelector(state => state.DesignReducer)
  const {isLoading} = useAppSelector(s => s.UploadFileReducer)
  return (
    <Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
      <DrawerHeader></DrawerHeader>
      <DrawerOverlay bg="rgba(0, 0, 0, 0.7)"/>
      <DrawerContent
        w={INTERFACE_WIDTH}
        maxH="80vh" mx="auto"
        bg='transparent'
        px='10px' pb='10px' borderTopRadius="24px"
        // className="unscroll" overflow='auto'
      >
        <Box
          rounded="24px"
          w={'100%'}
          h='100%'
          pb='30px' pt='8px'
          bg={placement_theme[def_theme]}
        >
          <Box onClick={onClose} mb='19px'>
            <Box bg={system_color} maxW="79px" h="3px" mx="auto" borderRadius="17px"/>
          </Box>

          <TitleComponents>{title}</TitleComponents>

          <DrawerBody className="unscroll" px={4} mt='30px' overflow='auto' maxH='420px'>
            {isLoading && <Progress size='xs' bg='none' isIndeterminate/>}
            {children}
          </DrawerBody>
        </Box>
      </DrawerContent>
    </Drawer>
  );
}

export default Placement;
