import React from 'react';

const ForgotSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.8333 11.1665H6.16667C5.24619 11.1665 4.5 11.9127 4.5 12.8332V18.6665C4.5 19.587 5.24619 20.3332 6.16667 20.3332H17.8333C18.7538 20.3332 19.5 19.587 19.5 18.6665V12.8332C19.5 11.9127 18.7538 11.1665 17.8333 11.1665Z" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.8335 11.1665V7.83317C7.8335 5.53198 9.69898 3.6665 12.0002 3.6665C14.3013 3.6665 16.1668 5.53198 16.1668 7.83317V11.1665" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  );
};

export default ForgotSvg;