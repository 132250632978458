import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ChangePassSlice {
  isOpenChangePassword: boolean;
  isLoading: boolean;
  isError: string | null
}

const initialState: ChangePassSlice = {
  isError: null,
  isLoading: false,
  isOpenChangePassword: false
}

export const changePasswordSlice = createSlice({
  name: "change-password",
  initialState,
  reducers: {
    setLoading(state){
      state.isLoading = true
    },
    changedPassword(state, action: PayloadAction<string | null>){
      state.isLoading = false;
      state.isError = action.payload
    },
    onToggleChangePassword(state, action: PayloadAction<boolean>){
      state.isOpenChangePassword = action.payload
    },
  }
})

export const ChangePasswordReducer = changePasswordSlice.reducer
export const {setLoading, changedPassword, onToggleChangePassword} = changePasswordSlice.actions