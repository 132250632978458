import React from 'react';

const InstagramSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5392 2.16667H8.46092C4.51758 2.16667 2.16675 4.5175 2.16675 8.46083V17.5283C2.16675 21.4825 4.51758 23.8333 8.46092 23.8333H17.5284C21.4717 23.8333 23.8226 21.4825 23.8226 17.5392V8.46083C23.8334 4.5175 21.4826 2.16667 17.5392 2.16667ZM13.0001 17.2033C10.6817 17.2033 8.79675 15.3183 8.79675 13C8.79675 10.6817 10.6817 8.79667 13.0001 8.79667C15.3184 8.79667 17.2034 10.6817 17.2034 13C17.2034 15.3183 15.3184 17.2033 13.0001 17.2033ZM19.4134 7.45333C19.3592 7.58333 19.2834 7.7025 19.1859 7.81083C19.0776 7.90833 18.9584 7.98417 18.8284 8.03833C18.6984 8.0925 18.5576 8.125 18.4167 8.125C18.1242 8.125 17.8534 8.01667 17.6476 7.81083C17.5501 7.7025 17.4742 7.58333 17.4201 7.45333C17.3659 7.32333 17.3334 7.1825 17.3334 7.04167C17.3334 6.90083 17.3659 6.76 17.4201 6.63C17.4742 6.48917 17.5501 6.38083 17.6476 6.2725C17.8967 6.02333 18.2759 5.90417 18.6226 5.98C18.6984 5.99083 18.7634 6.0125 18.8284 6.045C18.8934 6.06667 18.9584 6.09917 19.0234 6.1425C19.0776 6.175 19.1317 6.22917 19.1859 6.2725C19.2834 6.38083 19.3592 6.48917 19.4134 6.63C19.4676 6.76 19.5001 6.90083 19.5001 7.04167C19.5001 7.1825 19.4676 7.32333 19.4134 7.45333Z" fill="white"/>
    </svg>


  );
};

export default InstagramSvg;