import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface ICustomState {
  isLoading: boolean;
  isError: string | null
}

const initialState: ICustomState = {
  isError: null,
  isLoading: false,
}

export const customSlice = createSlice({
  name: "custom-button",
  initialState,
  reducers: {
    setLoading(state){
      state.isLoading = true
    },
    onSend(state, action: PayloadAction<string | null>){
      state.isLoading = false;
      state.isError = action.payload
    },
  }
})

export const CustomReducer = customSlice.reducer
