import React from 'react';
import {Box} from "@chakra-ui/react";
import ImageSlides from "./ImageSlides";
import TitleText from "../../../../components/ui/text/TitleText";
import {useAppSelector} from "../../../../config/hooks/redux";

const Products = () => {
  const {company: {galleries}} = useAppSelector(s => s.CompanyReducer)
  return (
    <Box mt='50px' w='100%' px='10px'>
      {galleries?.map((media, idx) => (
        <Box key={idx} mt='20px'>
          {!!media.title && <TitleText>{media.title}</TitleText>}
          <ImageSlides galley={media}/>
        </Box>
      ))}
    </Box>
  );
};

export default Products;