import React, { Component, ReactNode } from "react";
import {Box, Container, Text} from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";


class AccessDeniedPage extends Component {
  render(): ReactNode {
    return (
      <section>
        <Container maxW='container.xl'>
          <Box
            minH="100vh"
            color="#ffffff"
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            fontFamily="Poppins"
          >
            <Box fontSize="7xl" fontWeight="700" mb="20px">
              <FaLock />
            </Box>
            <Text
              textAlign="center"
              fontWeight="404"
              fontSize="2xl"
              lineHeight="110%"
            >
              You are not allowed to access this page.
            </Text>
          </Box>
        </Container>
      </section>
    );
  }
}

export default AccessDeniedPage;
