import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ICompany} from "../../../../models/company";
import {IContactButton} from "../../../../models/custom";
import {allButtonData} from "../../../../components/custom-buttons/data";

interface ICompanyState {
  isError: null | string;
  isLoading: boolean;
  company: ICompany;
  companies: ICompany[];
  buttons: IContactButton[]
}

export const empty_company = {
  id: 0,
  name: '',
  buttons: [],
  videos: [],
  galleries: [],
  user: 0,
  logo: '',
  description: '',
  locations: null
}

const initialState: ICompanyState = {
  isError: null,
  isLoading: false,
  company: empty_company,
  companies: [],
  buttons: [],
}

export const companySlice = createSlice({
  name: 'get/company',
  initialState,
  reducers: {
    setLoading(state) {
      state.isLoading = true
    },
    getCompanySuccess(state, action: PayloadAction<ICompany>) {
      state.isLoading = false
      state.company = action.payload
      state.isError = null
      state.buttons = [];
      for (let i = 0; i < allButtonData.length; i++) {
        action.payload.buttons.forEach(el => {
          if (el.button_type === allButtonData[i].type) {
            state.buttons.push({
              ...allButtonData[i],
              name: el.label,
              id: el.id,
              value: el.value
            })
          }
        })
      }
    },
    getCompanies(state, action: PayloadAction<ICompany[]>){
      state.companies = action.payload
    },

    getCompanyError(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.isError = action.payload
    },
  }
})

export const CompanyReducer = companySlice.reducer