import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootProfile} from "../../../../models/profile";
import {IContactButton} from "../../../../models/custom";
import {allButtonData} from "../../../../components/custom-buttons/data";

interface ProfileState {
  user: RootProfile;
  isError: string;
  isLoading: boolean;
  buttons: IContactButton[]
}

const initialState: ProfileState = {
  user: {
    id: 0,
    photo: "",
    full_name: "",
    position: "",
    uid: "",
    email: "",
    buttons: [],
    designs: null
  },
  isError: "",
  isLoading: false,
  buttons: []
}

export const profileSlice = createSlice({
  name: 'profile/get',
  initialState,
  reducers: {
    getProfile(state){
      state.isLoading = true;
    },
    getProfileSuccess(state, {payload}: PayloadAction<RootProfile>){
      state.user = payload
      state.isLoading = false;
      state.isError = "";
      state.buttons = [];
      for (let i = 0; i < allButtonData.length; i++) {
        payload.buttons.forEach(el => {
          if (el.button_type === allButtonData[i].type) {
            state.buttons.push({
              ...allButtonData[i],
              name: el.label,
              id: el.id,
              value: el.value
            })
          }
        })
      }
    },
    getProfileError(state, action: PayloadAction<string>){
      state.isLoading = false;
      state.isError = action.payload
    },
    uploadAvatar(state, {payload}: PayloadAction<any>){
      state.user.photo = payload
    },
    removeAvatar(state){
      state.user.photo = ""
    },
  }
})

export const ProfileReducer = profileSlice.reducer
export const {uploadAvatar, removeAvatar} = profileSlice.actions
