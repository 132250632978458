import React, {useState} from 'react';
import {Box, Input, InputGroup, InputRightElement, Textarea} from "@chakra-ui/react";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {useAppSelector} from "../../../config/hooks/redux";
import {buttons_theme, inputs_theme, main_theme} from "../../../config/colors";


export interface IInputProps {
  name: string;
  placeholder: string;
  type?: string;
  value?: string;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  validation?: boolean;
  required?: boolean;
  as?: 'input' | 'textarea';
  leftIcon?: null | JSX.Element
}


const ProfileInput = ({
                        handleChange,
                        name,
                        placeholder,
                        required,
                        type = 'text',
                        validation,
                        value,
                        as = 'input',
                        leftIcon = null
                      }: IInputProps) => {
  const [show, setShow] = useState(false)
  const {def_theme, system_color} = useAppSelector(state => state.DesignReducer)
  const initType = type
  type = show ? 'text' : type


  const rightElement = initType === "password" || leftIcon
  return (
    <Box mb='10px'>
      {as === "input" && <InputGroup>
        <Input
          onChange={handleChange}
          value={value}
          name={name}
          type={type}
          placeholder={placeholder}
          h='44px' w='100%'
          rounded='8px'
          border={`0.5px solid rgba(99, 99, 102, 1)`}
          bg={inputs_theme[def_theme]}
          fontSize='16px' letterSpacing='0.5px'
          fontWeight='400' fontFamily={"Poppins"}
          lineHeight='16px' color={system_color}
          _placeholder={{color: buttons_theme[def_theme], opacity: '0.68'}}
          isRequired={required}
        />
        {rightElement && <InputRightElement
          w="3rem"
          h="100%"
          display="flex"
          alignItems="center"
        >
          {leftIcon && <Box
            fontSize="24px"
            color="#A8A8A8"
          >{leftIcon}</Box>}
          { initType === "password" && <Box
            onClick={() => setShow(!show)}
            fontSize="28px"
            color="#A8A8A8"
            cursor="pointer"
          >
            {show ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
          </Box>}
        </InputRightElement>}
      </InputGroup>}

      {as === "textarea" && <Textarea
        onChange={handleChange}
        value={value}
        name={name}
        placeholder={placeholder}
        h='75px' w='100%'
        rounded='8px' pt='13px'
        border={`0.5px solid rgba(99, 99, 102, 1)`}
        bg={inputs_theme[def_theme]}
        fontSize='16px' letterSpacing='0.5px'
        fontWeight='400' fontFamily={"Poppins"}
        lineHeight='16px'  color={system_color}
        _placeholder={{color: buttons_theme[def_theme], opacity: '0.68'}}
        isRequired={required}
      />}
    </Box>
  );
};

export default ProfileInput;