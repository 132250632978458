import React from 'react';
import {Box, Button} from "@chakra-ui/react";

interface Props {
  disabled: boolean
}
const SaveChanges = ({disabled}: Props) => {
  return (
    <Button
      type='submit'
      variant='none'
      bg='transparent'
      // border='none'
      color='rgba(55, 154, 255, 1)' h='36px'
      border='1px solid rgba(55, 154, 255, 1)'
      fontWeight='500' px={3} py='0'
      fontFamily='Poppins'
      fontSize='14px'
      lineHeight='22px'
      opacity={disabled ? ".7" : '1'}
      _active={{opacity: '.7'}}
    >
      Save changes
    </Button>
  );
};

export default SaveChanges;