import React from 'react';
import {Outlet} from "react-router-dom";
import {useAuthUser} from "../config/hooks/userAccess";
import AccessDeniedPage from "../components/AccessDeniedPage";

const ProtectedInterfacePage = () => {
  const isAuth = useAuthUser()
  return isAuth ? <Outlet/> : <AccessDeniedPage/>;
};

export default ProtectedInterfacePage;