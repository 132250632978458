import React, {useEffect, useState} from 'react';
import PlusButton from "../../../../components/ui/Buttons/PlusButton";
import {Box, useDisclosure} from "@chakra-ui/react";
import {ILocation} from "../../../../models/company";
import Placement from "../../../../components/ui/placement";
import ProfileInput from "../../../../components/ui/inputs/ProfileInput";
import {TfiLocationPin} from "react-icons/tfi";
import DefButton from "../../../../components/ui/Buttons/DefButton";
import {useAppDispatch, useAppSelector} from "../../../../config/hooks/redux";
import {addLocation} from "../CompanyReducer/action";

const Location = () => {
  const {isOpen, onOpen, onClose} = useDisclosure()
  const dispatch = useAppDispatch()
  const {company: {locations}} =  useAppSelector(s => s.CompanyReducer)
  const {isLoading} =  useAppSelector(s => s.PostProfileReducer)
  const [value, setValue] = useState<ILocation>({
    tower_name: "",
    map_link: '',
    street: '',
    city_country: '',
    main_branch: '',
  })

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue({...value, [e.target.name]: e.target.value})
  }

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    dispatch(addLocation(value, onClose))
  }
  useEffect(() => {
    if (!!locations){
      setValue({...locations})
    }
  }, [locations])

  return (
    <>
      <PlusButton name={"Location"} onClick={onOpen}/>

      <Placement isOpen={isOpen} onClose={onClose} title={"Location"}>
        <form onSubmit={onSubmit}>
          <ProfileInput
            handleChange={handleChange}
            value={value.main_branch}
            name={"main_branch"}
            placeholder={"Office-Shop-Main branch"}
            required={false}
          />
          <ProfileInput
            handleChange={handleChange}
            value={value.tower_name}
            name={"tower_name"}
            placeholder={"Tower name & Unit No "}
            required={false}
          />
          <ProfileInput
            handleChange={handleChange}
            value={value.street}
            name={"street"}
            placeholder={"Street / Area - Business Bay "}
            required={false}
          />
          <ProfileInput
            handleChange={handleChange}
            value={value.city_country}
            name={"city_country"}
            placeholder={"City/Country-Dubai -UAE "}
            required={false}
          />
          <ProfileInput
            handleChange={handleChange}
            value={value.map_link}
            name={"map_link"}
            type={"url"}
            leftIcon={<TfiLocationPin/>}
            placeholder={"Map Link"}
            required={false}
          />
          <Box mt='40px'>
            <DefButton isLoading={isLoading}>{'SAVE'}</DefButton></Box>
        </form>
      </Placement>
    </>
  );
};

export default Location;