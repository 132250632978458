export function hexToRGB (hex: any, opacity: number = 1){
  hex = hex.slice(1)
  hex = '0x' + hex
  let r = hex >> 16 & 0xFF
  let g = hex >> 8 & 0xFF
  let b = hex & 0xFF
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}


export function toLocalStorage(key: string, value: string) {
  localStorage.setItem(key, value);
}

export function getLocalStorage(key: string): string {
  return localStorage.getItem(key) || "";
}

export function removeLocalStorage(key: string) {
  localStorage.removeItem(key);
}


export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) {
    return '0 Bytes'
  }

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const result = parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
  return `${result} ${sizes[i]}`
}




export function getYouTubeVideoId(url: string) {
  let videoId = "";

  // Regular expression to match YouTube video URLs in various formats
  const patterns = [
    /youtu\.be\/([A-Za-z0-9_-]+)/, // Shortened URL
    /[?&]v=([A-Za-z0-9_-]+)/, // Regular URL with "v="
    /\/embed\/([A-Za-z0-9_-]+)/, // URL with "/embed/"
    /\/v\/([A-Za-z0-9_-]+)/, // URL with "/v/"
    /\/watch\?v=([A-Za-z0-9_-]+)/, // URL with "/watch?v="
    /\/watch\?.*?v=([A-Za-z0-9_-]+)/, // URL with "/watch?" and "v="
    /\/(?:embed|v)\/([A-Za-z0-9_-]+)(?:\?|&|$)/, // Embed or v parameter
  ];

  // Iterate through patterns to find a match
  for (const pattern of patterns) {
    const match = url.match(pattern);
    if (match && match[1]) {
      videoId = match[1];
      break;
    }
  }

  return videoId;
}

export const downloadFile = (fileUrl: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const getFormatDate = (date: Date) => {
  const _date = new Date(date);
  const day = _date.getDate();
  const month = _date.getMonth() + 1; // Месяцы в JavaScript начинаются с 0, поэтому добавляем 1
  const year = _date.getFullYear();
  return `${day.toString().padStart(2, "0")}.${month
    .toString()
    .padStart(2, "0")}.${year}`;
};

export const createVcfFile = (name: string, contact: string, email: string) => {
  // Создаем текст VCF файла
  const vcfText = `BEGIN:VCARD
VERSION:3.0
EMAIL:${email}
FN:${name}
TEL:${contact}
END:VCARD`;

  // Создаем Blob из текста VCF
  const blob = new Blob([vcfText], {type: "text/vcard"});

  // Создаем ссылку для скачивания файла
  const url = window.URL.createObjectURL(blob);

  downloadFile(url, `${name + contact}.vcf`);
};

export const saveContactVcf = (name: string, photo: string, email: string, contact: string) => {
  // Создаем текст VCF файла
  const vcfText = `BEGIN:VCARD
VERSION:3.0
PHOTO:${photo}
EMAIL:${email}
FN:${name}
TEL:${contact}
END:VCARD`;

  // Создаем Blob из текста VCF
  const blob = new Blob([vcfText], {type: "text/vcard"});

  // Создаем ссылку для скачивания файла
  const url = window.URL.createObjectURL(blob);

  downloadFile(url, `${name + contact}.vcf`);
};


export const navigateToUrl = (url: string, isMobile?: boolean) => {
  if (isMobile) {
    window.open(url, "_self");
  } else {
    window.open(url, "_blank");
  }
};