import React from 'react';

const TiktokSvg = () => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.1018 3H13.8363V16.865C13.8363 20.7472 8.39384 20.7472 8.39384 16.865C8.39384 13.7592 10.9337 13.7223 12.2036 14.092V10.7644C7.41419 9.87704 5.49118 13.3525 5.12834 15.2012C4.25755 20.5253 8.03099 22.5959 10.0266 22.9656C15.6867 23.4093 17.1018 19.4531 17.1018 17.4196V9.6552C19.2788 11.4299 21.2743 11.5039 22 11.319V7.9914C18.0814 7.54772 17.1018 4.47893 17.1018 3Z" fill="white" stroke="black" strokeWidth="0.5"/>
    </svg>

  );
};

export default TiktokSvg;