import React, {useState} from 'react';
import {Box, Divider, Flex, Input, InputGroup, InputRightElement} from "@chakra-ui/react";
import DefButton from "../../components/ui/Buttons/DefButton";
import {API_ADDRESS, PUBLIC_API} from "../../api";
import {useNavigate} from "react-router-dom";
import {MdFileCopy} from "react-icons/md";
import {IoCheckmarkDone} from "react-icons/io5";

const UserCreatePage = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState(false);
  const [user, setUser] = useState<string>("");

  const navigate = useNavigate();

  async function copyTextToClipboard(text: string) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    if (!!user){
      copyTextToClipboard(user)
        .then(() => {
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const fetchAddUser = () => {
    setLoading(true);
    setUser("");
    PUBLIC_API.get("create-profile/")
      .then(({data}) => {
        setUser(`https://imperial-card.com/user/${data.uid}`);
        setLoading(false);
      })
      .catch(() => {
        // localStorage.removeItem("access");
        navigate("/");
      });
  };
  return (
    <Box>
      <Box w='60%' mx='auto'>
        <DefButton
          type={"button"}
          onClick={fetchAddUser}
          bg={"rgba(10, 132, 255, 1)"}
          isLoading={isLoading}
        >CREATE</DefButton>
      </Box>
      <InputGroup mt='4'>
        <Input
          isReadOnly={true}
          value={user}
          fontSize='15px'
          color='rgba(0, 128, 221, 1)'
          py={6} px={6}
          bg='white'
          border='none'
        />
        <InputRightElement w='80px' display='flex' h='100%'  alignItems='center'>
          <Divider orientation='vertical' mr={5}/>
          <Flex
            alignItems='center'
            h='100%'
            onClick={handleCopyClick}
            color='rgba(10, 132, 255, 1)'
            fontSize='1.4rem'
            cursor='pointer'>
            {isCopied ? <IoCheckmarkDone/> : <MdFileCopy/>}
          </Flex>
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};

export default UserCreatePage;
