import React from 'react';
import {Avatar, Box, Flex, Heading, Text} from "@chakra-ui/react";
import {useAppSelector} from "../../../../config/hooks/redux";
import {box_theme} from "../../../../config/colors";

const CompanyData = () => {
  const {company} = useAppSelector(s => s.CompanyReducer)
  const {def_theme, system_color, def_font} = useAppSelector(state => state.DesignReducer)
  return (
    <Box
      pt='30px'>
      <Box
        bg={box_theme[def_theme]}
        rounded='10px' p={4}
      >
        <Flex alignItems='center'>
          <Avatar w='60px' h='60px' mr={4} name='Christian Nwamba' src={company.logo}/>
          <Heading
            textTransform='uppercase'
            lineHeight='28px'
            letterSpacing='0.35px'
            fontSize='18px'
            fontWeight='700'
            fontFamily={def_font}
            color={system_color}
          >{company.name}</Heading>
        </Flex>

        <Text
          lineHeight='21.5px'
          letterSpacing='0.07px'
          fontSize='14px' mt={4}
          fontWeight='400'
          fontFamily='Poppins'
          color={system_color}
          opacity='.7'
        >{company.description}
        </Text>
      </Box>
    </Box>
  );
};

export default CompanyData;