import React from 'react';
import {Heading} from "@chakra-ui/react";
import {useAppSelector} from "../../../config/hooks/redux";

const TitleComponents = ({children}: {children: JSX.Element | string}) => {
  const {system_color} = useAppSelector(state => state.DesignReducer)
  return (
    <Heading fontFamily='Poppins' py={2} fontWeight='600' textAlign='center' lineHeight='22px'
             fontSize='20px' w='full' letterSpacing='5%' mx='auto' color={system_color}
    >{children}</Heading>
  );
};

export default TitleComponents;