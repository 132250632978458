import axios, { AxiosInstance } from "axios";

// local dependencies
import { getLocalStorage, toLocalStorage } from "../config/helpers";

// базовый URL для API
export const API_ADDRESS = process.env.REACT_APP_API_URL;

// Axios-инстанс для публичных запросов
export const PUBLIC_API: AxiosInstance = axios.create({
  baseURL: API_ADDRESS,
});

//Axios-инстанс для приватных запросов
const PRIVATE_API: AxiosInstance = axios.create({
  baseURL: API_ADDRESS,
});

//интерцепторы для приватных запросов перед отправкой запроса
PRIVATE_API.interceptors.request.use((config: any) => {
  config.headers.Authorization = `Bearer ${getLocalStorage("access")}`;
  return config;
});

//интерцепторы для приватных запросов после получения ответа
PRIVATE_API.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    const originalRequest = err.config;
    if (err.response.status === 401 && !err.config._isRetry) {
      // помечаем запрос как повторный, чтобы избежать бесконечных циклов
      originalRequest._isRetry = true;
      try {
        // отправляем запрос на обновление access токена с помощью refresh-токена
        const { data } = await axios.post(
          `${API_ADDRESS}auth/jwt/refresh/`,
          {
            refresh: getLocalStorage("refresh"),
          }
        );
        // обновляем токен доступа в локальном хранилище
        toLocalStorage("access", data.access);
        // повторно отправляем исходный запрос
        return PRIVATE_API.request(originalRequest);
      } catch (e) {
        return Promise.reject(e);
      }
    } else {
      return Promise.reject(err);
    }
  }
);

export default PRIVATE_API;
