import {AppDispatch} from "../../../../../redux/store";
import {API_ADDRESS, PUBLIC_API} from "../../../../../api";
import {toastCreator} from "../../../../../config/toast";
import {saveContactSlice} from "./slice";
import {downloadFile} from "../../../../../config/helpers";

export const saveContact = (user_id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(saveContactSlice.actions.setLoading());
    const {data} = await PUBLIC_API.post(`${user_id}/vcard/`)
    const url = API_ADDRESS + encodeURIComponent(data.file_url);
    downloadFile(url, data.file_url);
    dispatch(saveContactSlice.actions.onSavedContact(null));
  } catch (e: any) {
    toastCreator("error", e.response?.data?.message || e.detail);
    dispatch(
      saveContactSlice.actions.onSavedContact(
        e.response?.data?.message || e.detail
      )
    );
  }
}