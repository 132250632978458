import React from 'react';
import {Button, Flex, Input, InputGroup, InputRightElement, Stack} from "@chakra-ui/react";
import {CgSoftwareUpload} from "react-icons/cg";
import {useAppSelector} from "../../../config/hooks/redux";
import {buttons_theme, inputs_theme} from "../../../config/colors";
import FileInput from "./FileInput";


interface Props {
  handleChange: (e: React.ChangeEvent) => void;
  value: string;
  placeholder: string;
  accept?: string[]
}

const def_accepts = ['.png', '.jpg', '.HEIC', '.jpeg', '.svg', '.gif']

const AddFileInput = ({handleChange, value, placeholder, accept = def_accepts}: Props) => {
  const {def_theme, system_color} = useAppSelector(state => state.DesignReducer)
  return (
    <InputGroup mb='10px' w='100%'>
      <Input
        placeholder={placeholder}
        value={value}
        h='44px' w='100%'
        rounded='8px'
        variant='none'
        border={`0.5px dashed rgba(99, 99, 102, 1)`}
        bg={inputs_theme[def_theme]}
        fontSize='16px' letterSpacing='0.5px'
        fontWeight='400' fontFamily={"Poppins"}
        lineHeight='16px' color={system_color}
        _placeholder={{color: buttons_theme[def_theme], opacity: '0.68'}}
        isReadOnly={true}
      />
      <InputRightElement w="3rem" h="100%">
        <Stack
          as='label'
          position='relative'
          display='inline-block'
          cursor='pointer'
          w="100%"
          h="100%"
          overflow='hidden'
        >
          <FileInput
            handleChange={handleChange}
            accept={accept}
            multi={false}/>
          <Button
            variant='none' as='span' p='0'
            position='relative'
            display='inline-block'
            color='rgba(55, 154, 255, 1)' fontSize='1.4rem'
            _active={{color: '#0080DDFF'}}
            w='100%' h='100%'>
            <Flex
              h='100%'
              justifyContent='center'
              alignItems='center'
              fontSize='2rem'
            ><CgSoftwareUpload/></Flex></Button>
        </Stack>
      </InputRightElement>
    </InputGroup>
  );
};

export default AddFileInput;